import React, { Component } from 'react';
import Axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { getAccessToken } from '../../hooks/AccessToken';
import Constant from '../Constant';

class HomepageBannerManagement extends Component {
    state = {
        banners: [],
        showModal: false,
        name: '',
        image: '',
        type: 'contest',
        eventId: '',
        message: '',
        rank: 0,
        eventOptions: [],
        isEditing: false, // New state to track editing
        editBannerId: null, // New state to store the ID of the banner being edited
    };

    defaultImageUrl = 'https://t3.ftcdn.net/jpg/01/15/40/12/360_F_115401245_GYPbMWpOT59rUcfObToRcFjyF2KFYIAe.jpg';

    componentDidMount() {
        this.fetchBanners();
    }

    fetchBanners = async () => {
        try {
            const token = getAccessToken();
            const response = await Axios.get("https://api.storytent.in/api/HomepageBanner/get-all", {
                headers: { token }
            });
            this.setState({ banners: response.data.data });
        } catch (err) {
            console.error("Error fetching banners:", err);
        }
    };

    handleChange = async (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        if (name === 'type') {
            await this.fetchEventOptions(value);
        }
    };

    fetchEventOptions = async (type) => {
        try {
            const token = getAccessToken();
            let response;
            
            if (type === 'contest') {
                response = await Axios.post("https://api.storytent.in/api/contest/get-all", {}, { headers: { token } });
            } else if (type === 'open-mic') {
                response = await Axios.get("https://api.storytent.in/api/open-mic/get-all", { headers: { token } });
            } else if (type === 'openMic') {
                response = await Axios.post("https://api.storytent.in/api/openMic/get-all",{}, { headers: { token } });
            }
            
            if (response?.data?.data) {
                this.setState({ eventOptions: response.data.data });
            }
        } catch (err) {
            console.error(`Error fetching ${type} options:`, err);
        }
    };

    handleShowModal = () => {
        this.setState({ showModal: true });
    };

    handleModalClose = () => {
        this.setState({
            showModal: false,
            name: '',
            image: '',
            type: 'contest',
            eventId: '',
            rank: 0,
            eventOptions: [],
            isEditing: false,
            editBannerId: null
        });
    };

    handleAddBanner = async () => {
        const { name, image, type, eventId, rank, isEditing, editBannerId } = this.state;

        try {
            const token = getAccessToken();
            const selectedEvent = this.state.eventOptions.find(event => event._id === eventId);

            const bannerName = selectedEvent.name;
            const bannerImage = (selectedEvent.image && selectedEvent.image !== '') ? selectedEvent.image :
                (type === 'openMic' || type === 'open-mic') ? this.defaultImageUrl : '';

            const bannerData = { name: bannerName, image: bannerImage, type, eventId, rank };

            let response;
            if (isEditing) {
                response = await Axios.put(`https://api.storytent.in/api/HomepageBanner/edit/${editBannerId}`, bannerData, {
                    headers: { token }
                });
            } else {
                response = await Axios.post("https://api.storytent.in/api/HomepageBanner", bannerData, {
                    headers: { token }
                });
            }

            if (response.data.status === Constant.statusSuccess) {
                this.setState({ message: isEditing ? "Banner updated successfully" : "Banner added successfully", messageColor: 'green' });
                this.fetchBanners();
                this.handleModalClose();
            } else {
                this.setState({ message: response.data.message, messageColor: 'red' });
            }
        } catch (err) {
            console.error(isEditing ? "Error updating banner:" : "Error adding banner:", err);
        }
    };

    handleEditBanner = (banner) => {
        this.setState({
            showModal: true,
            isEditing: true,
            editBannerId: banner._id,
            type: banner.type,
            eventId: banner.eventId._id,
            rank: banner.rank,
        }, () => {
            // Fetch the event options for the type and populate the modal
            this.fetchEventOptions(banner.type);
        });
    };

    handleDeleteBanner = async (id) => {
        if (!window.confirm("Delete the Banner?")) return;
        try {
            const token = getAccessToken();
            const response = await Axios.delete(`https://api.storytent.in/api/HomepageBanner/delete/${id}`, {
                headers: { token }
            });
            if (response.data.status === Constant.statusSuccess) {
                this.setState({ message: "Banner deleted successfully", messageColor: 'green' });
                this.fetchBanners();
            } else {
                this.setState({ message: response.data.message, messageColor: 'red' });
            }
        } catch (err) {
            console.error("Error deleting banner:", err);
        }
    };

    render() {
        const { banners, showModal, type, eventId, message, messageColor, eventOptions, isEditing } = this.state;

        return (
            <div className="content-page">
                <div className="content">
                    <h4 className="page-title-heading">Homepage Banner Management</h4>
                    <Button variant="info" className="mb-3" onClick={this.handleShowModal}>Add New Banner</Button>
                    <p style={{ color: messageColor }}>{message}</p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Type</th>
                                <th>Event ID</th>
                                <th>Rank</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {banners.map((banner, index) => (
                                <tr key={index}>
                                    <td>{banner.name}</td>
                                    <td><img src={banner.image} alt="Banner" style={{ width: 50, height: 50 }} /></td>
                                    <td>{banner.type}</td>
                                    <td>{banner.type === 'openMic' ? banner.eventId : banner.eventId._id}</td>
                                    <td>{banner.rank}</td>
                                    <td>
                                        <Button variant="warning" onClick={() => this.handleEditBanner(banner)}>Edit</Button>
                                        <Button variant="danger" onClick={() => this.handleDeleteBanner(banner._id)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Modal show={showModal} onHide={this.handleModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{isEditing ? "Edit Banner" : "Add New Banner"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="formType">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control as="select" name="type" value={type} onChange={this.handleChange}>
                                        <option value="contest">Contest</option>
                                        <option value="open-mic">open-mic</option>
                                        <option value="openMic">openMic</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="formEventId">
                                    <Form.Label>Event ID</Form.Label>
                                    <Form.Control as="select" name="eventId" value={eventId} onChange={this.handleChange}>
                                        <option value="">Select an event</option>
                                        {eventOptions.map((event) => (
                                            <option key={event._id} value={event._id}>{event.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="formRank">
                                    <Form.Label>Rank</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="rank"
                                        value={this.state.rank}
                                        onChange={this.handleChange}
                                        placeholder="Enter rank"
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleModalClose}>Close</Button>
                            <Button variant="primary" onClick={this.handleAddBanner}>
                                {isEditing ? "Update Banner" : "Add Banner"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default HomepageBannerManagement;
