import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Constant from '../Constant';
import $ from 'jquery';
import { getAccessToken } from '../../hooks/AccessToken';
import { Modal, Button, Form } from 'react-bootstrap';

class DataManagement extends Component {
    state = {
        ERROR: true,
        DataList: [],
        title: '',
        criteria: '',
        type: '',
        status: '',
        icon:null,
        showModal: false,
        currentBadge: null,
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeStatus = async (id, status) => {
        try {
            let token = getAccessToken();
            const response = await Axios.patch(Constant.apiBasePath + 'badge/changeStatus/' + id, { status: status }, { headers: { 'token': token } });
            let { data } = response;
            if (data.status === Constant.statusSuccess) {
                $('#gen-message').text(data.message);
                $('#gen-message').css('color', 'green');
                this.handleSubmit();
            } else {
                $('#gen-message').text(data.message);
                $('#gen-message').css('color', 'red');
            }
        } catch (err) {
            console.log(err);
        }
    };

    deleteBadge = async event => {
        try {
            let token = getAccessToken();
            const response = await Axios.delete(Constant.apiBasePath + 'badge/' + event, { headers: { 'token': token } });
            let data = response.data;
            if (data.status === Constant.statusSuccess) {
                $('#gen-message').text(data.message);
                $('#gen-message').css('color', 'green');
                this.handleSubmit();
                setTimeout(function () { $("#gen-message").text(''); }, 3000);
            } else {
                $('#gen-message').text(data.message);
                $('#gen-message').css('color', 'red');
                setTimeout(function () { $("#gen-message").text(''); }, 3000);
            }
        } catch (err) {
            console.log(err);
        }
    };

    updateBadge = (badge) => {
        this.setState({
            currentBadge: badge,
            title: badge.title,
            criteria: badge.criteria,
            type: badge.type,
            status: badge.status,
            showModal: true
        });
    };

    handleSubmit = async () => {
        try {
            let token = getAccessToken();
            const response = await Axios.post(Constant.apiBasePath + 'badge/get-all', { 'pageNo': 1 }, { headers: { 'token': token } });
            const { data } = response.data;
            this.setState({ DataList: data, ERROR: false });
        } catch (err) {
            console.log(err);
        }
    };

    handleModalClose = () => {
        this.setState({
            showModal: false,
            currentBadge: null,
            title: '',
            criteria: '',
            type: '',
            status: ''
        });
    };

    handleModalSave = async () => {
        const { currentBadge, title, criteria, type, status } = this.state;
        try {
            let token = getAccessToken();
            const response = await Axios.patch(Constant.apiBasePath + 'badge/edit/' + currentBadge._id, { title, criteria, type, status }, { headers: { 'token': token } });
            let data = response.data;
            if (data.status === Constant.statusSuccess) {
                $('#gen-message').text(data.message);
                $('#gen-message').css('color', 'green');
                this.handleSubmit();
                this.handleModalClose();
            } else {
                $('#gen-message').text(data.message);
                $('#gen-message').css('color', 'red');
            }
        } catch (err) {
            console.log(err);
        }
    };

    componentDidMount() {
        this.handleSubmit();
    }

    render() {
        const { DataList, showModal, title, criteria, type, status } = this.state;
        let i = 0;

        let bodyData = '';
        if (DataList.length > 0) {
            bodyData = DataList.map(el => {
                i++;
                let statusLabel = el.status === 1 ? "Active" : "Inactive";
                let typeLabel = el.type === 2 ? "Reader" : "Writer";

                return (
                    <tr key={el._id}>
                        <td>{i}</td>
                        <td>{typeLabel}</td>
                        <td>{el.criteria}</td>
                        <td>{el.title}</td>
                        <td><img src={el.icon} alt="ind" style={{ width: 50, height: 50 }} /></td>
                        <td>{statusLabel}</td>
                        <td>
                            <button className="btn btn-info" onClick={() => { this.changeStatus(el._id, el.status) }}>{statusLabel}</button>
                            <button className="btn btn-danger Banner-btn" onClick={() => { if (window.confirm('Delete the Badge?')) { this.deleteBadge(el._id) } }}><i className="mdi mdi-delete" title='Delete Badge'></i></button>
                            <button className="btn btn-info Banner-btn edit-banner ml5" onClick={() => { this.updateBadge(el) }}><i className="mdi mdi-square-edit-outline" title='Update Badge'></i></button>
                        </td>
                    </tr>
                );
            });
        }

        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-6">
                            <div className="page-title-box">
                                <h4 className="page-title-heading">Badge Management</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <Link to="/add-badge"><button type="button" className="btn btn-info mb-2 mr-2 float-right">Add New</button></Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="tab-content">
                                        <div className="tab-pane show active" id="buttons-table-preview">
                                            <table id="complex-header-datatable" className="table dt-responsive nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Type</th>
                                                        <th>Criteria</th>
                                                        <th>Title</th>
                                                        <th>Icon</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bodyData}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={showModal} onHide={this.handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Badge</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" name="title" value={title} onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formCriteria">
                                <Form.Label>Criteria</Form.Label>
                                <Form.Control type="text" name="criteria" value={criteria} onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group controlId="formType">
                                <Form.Label>Type</Form.Label>
                                <Form.Control as="select" name="type" value={type} onChange={this.handleChange}>
                                    <option value="1">Writer</option>
                                    <option value="2">Reader</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formStatus">
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" name="status" value={status} onChange={this.handleChange}>
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleModalClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleModalSave}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default DataManagement;
