module.exports = {
	'statusSuccess': 'Success',
	'statusFailure': 'Failure',
	'perPage': 10,
	// 'apiBasePath': 'http://localhost:8083/api/',
	// 'apiBasePath': 'http://13.234.187.136:8083/api/',
    // 'apiBasePath': 'https://storytent.b2cinfohosting.in/api/',
    'apiBasePath': 'https://api.storytent.in/api/',
	// 'apiBasePath': 'http://13.232.149.183:8083/api/',
	'somethingError': 'Something Error!'
}
