import React, { Component } from 'react';
import Axios from 'axios';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';
import { Link } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class SubOpenMics extends Component {
    
    state = {
        subOpenMics: []
    };

    componentDidMount() {
        this.fetchSubOpenMics();
    }

    fetchSubOpenMics = async () => {
        try {
            const { match } = this.props; 
            const { cId } = match.params; 
            let token = getAccessToken();
          
            const response = await Axios.post(
                Constant.apiBasePath + `openMic/get-all/${cId}`, 
                {},
                { headers: { 'token': token } }
            );
            const { data } = response;
            if (data.status === 'Success') {
                this.setState({ subOpenMics: data.data });
            } else {
                console.error('Failed to fetch sub open mics:', data.message);
            }
        } catch (error) {
            console.error('Error fetching sub open mics:', error);
        }
    };

    deleteSubOpenMic = async (micId) => {
        try {
            let token = getAccessToken();
            const response = await Axios.delete(
                `${Constant.apiBasePath}openMic/deleteSub/${micId}`,
                { headers: { 'token': token } }
            );
            const { data } = response;
            if (data.status === 'Success') {
                // Remove the deleted sub open mic from the state
                this.setState(prevState => ({
                    subOpenMics: prevState.subOpenMics.filter(mic => mic._id !== micId)
                }));
                // Show success message
                alert('Sub open mic deleted successfully');
            } else {
                console.error('Failed to delete sub open mic:', data.message);
            }
        } catch (error) {
            console.error('Error deleting sub open mic:', error);
        }
    };

    render() {
        const { subOpenMics } = this.state;
        const { match } = this.props; 
        const { cId } = match.params; 

        return (
            <div className="content-page">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="page-title-heading">Sub Open Mics</h4>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-md-right">
                                            <Link to={`/add-subcontest/${cId}`} className="btn btn-info">Add New</Link> 
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Name</th>
                                                <th>About</th>
                                                <th>Start Date</th>
                                                <th>Closure Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subOpenMics.map((mic, index) => (
                                                <tr key={mic._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{mic.name}</td>
                                                    <td>{mic.about}</td>
                                                    <td>{mic.startDate}</td>
                                                    <td>{mic.closureDate}</td>
                                                    <td>{mic.status === 1 ? 'Active' : 'Inactive'}</td>
                                                    <td>
                                                        <button className="btn btn-danger" onClick={() => this.deleteSubOpenMic(mic._id)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px' }} />
                                                        </button>
                                                        <Link to={`/edit-subcontest/${mic._id}`}  className="btn btn-primary" style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }}>
                                                            <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </Link>
                                                    </td>
                                                    <Link to={`/participants-data/${mic._id}`} className="btn btn-info">Participants</Link> 

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SubOpenMics;
