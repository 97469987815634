// Import necessary libraries and components
import React, { Component } from 'react';
import Axios from 'axios';
import { getAccessToken } from '../../hooks/AccessToken';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class Participants extends Component {
    state = {
        participants: [],
        showModal: false,
        newParticipantName: '',
        editParticipantId: null,
        editParticipantName: '',
    };

    componentDidMount() {
        this.fetchParticipants();
    }

    fetchParticipants = async () => {
        const { match } = this.props;
        const { sId } = match.params;

        try {
            const token = getAccessToken();
            const response = await Axios.get(`https://api.storytent.in/api/openMic/getParticipantsById/${sId}`, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.status === 'Success') {
                this.setState({ participants: data.data });
            } else {
                console.error('Failed to fetch participants:', data.message);
            }
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    };

    addParticipant = async () => {
        const { match } = this.props;
        const { sId } = match.params;
        try {
            const token = getAccessToken();
            const response = await Axios.post(`https://api.storytent.in/api/openMic/add-participant/${sId}`, {
                name: this.state.newParticipantName
            }, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.status === 'Success') {
                console.log('Participant added successfully');
                this.fetchParticipants(); 
                this.setState({ newParticipantName: '', showModal: false }); 
            } else {
                console.error('Failed to add participant:', data.message);
            }
        } catch (error) {
            console.error('Error adding participant:', error);
        }
    };

    editParticipant = async () => {
        const { editParticipantId, editParticipantName } = this.state;
        try {
            const token = getAccessToken();
            const response = await Axios.patch(`https://api.storytent.in/api/openMic/edit-participant/${editParticipantId}`, {
                name: editParticipantName
            }, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.status === 'Success') {
                console.log('Participant updated successfully');
                this.fetchParticipants(); // Refresh participant list
                this.setState({ editParticipantId: null, editParticipantName: '', showModal: false }); // Clear input and close modal
            } else {
                console.error('Failed to update participant:', data.message);
            }
        } catch (error) {
            console.error('Error updating participant:', error);
        }
    };

    handleOpenModal = () => {
        this.setState({ showModal: true });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false, editParticipantId: null, editParticipantName: '' });
    };

    handleInputChange = (e) => {
        this.setState({ newParticipantName: e.target.value });
    };

    handleEditInputChange = (e) => {
        this.setState({ editParticipantName: e.target.value });
    };

    handleEditParticipant = (participant) => {
        this.setState({ editParticipantId: participant._id, editParticipantName: participant.name, showModal: true });
    };

    render() {
        const { participants, showModal, newParticipantName, editParticipantName } = this.state;

        return (
            <div className="content-page">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="page-title-heading">Participants</h4>
                                    <button className="btn btn-info" onClick={this.handleOpenModal}>Add New</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Name</th>
                                                <th>Likes</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {participants.map((participant, index) => (
                                                <tr key={participant._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{participant.name}</td>
                                                    <td>{participant.likes.length}</td>
                                                    <td>
                                                        <button className="btn btn-primary" style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }} onClick={() => this.handleEditParticipant(participant)}>
                                                            <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Add Participant"
                    style={{
                        content: {
                            width: '300px', // Adjust width as needed
                            height: '200px', // Adjust height as needed
                            margin: 'auto', // Center the modal horizontally
                            backgroundColor: '#fff', // Background color of the modal
                            borderRadius: '10px', // Border radius
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Box shadow
                            padding: '20px' // Padding inside the modal
                        }
                    }}
                >
                    <h2>{this.state.editParticipantId ? 'Edit Participant' : 'Add New Participant'}</h2>
                    <input type="text" value={this.state.editParticipantId ? editParticipantName : newParticipantName} onChange={this.state.editParticipantId ? this.handleEditInputChange : this.handleInputChange} />
                    <button onClick={this.state.editParticipantId ? this.editParticipant : this.addParticipant}>{this.state.editParticipantId ? 'Save' : 'Add'}</button>
                    <button onClick={this.handleCloseModal}>Cancel</button>
                </Modal>
            </div>
        );
    }
}

export default Participants;
