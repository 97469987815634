import React, { Component } from 'react';
import Axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { getAccessToken } from '../../hooks/AccessToken';
import Constant from '../Constant';

class SpotlightManagement extends Component {
    state = {
        banners: [],
        showModal: false,
        imageUrl: '',
        quote: '',
        storyLink: '',
        message: '',
        messageColor: '',
        isEditing: false,
        editBannerId: null,
        file: null, // New file state
    };

    componentDidMount() {
        this.fetchBanners();
    }

    fetchBanners = async () => {
        try {
            const token = getAccessToken();
            const response = await Axios.get("https://api.storytent.in/api/spotlight/get-all", {
                headers: { token },
            });
            this.setState({ banners: response.data.data });
        } catch (err) {
            console.error("Error fetching banners:", err);
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleFileChange = (e) => {
        const file = e.target.files[0];
        this.setState({ file });
    };

    handleShowModal = () => {
        this.setState({ showModal: true });
    };

    handleModalClose = () => {
        this.setState({
            showModal: false,
            imageUrl: '',
            quote: '',
            storyLink: '',
            isEditing: false,
            editBannerId: null,
            file: null,
        });
    };

    handleUploadFile = async () => {
        const { file } = this.state;
        if (!file) return;

        const formData = new FormData();
        formData.append('spotlight', file);
        
        try {
            const token = getAccessToken();
            const response = await Axios.post("http://localhost:8083/api/spotlight/uploadFileOnS3", formData, {
                headers: { token, 'Content-Type': 'multipart/form-data' },
            });

            if (response.data.status === Constant.statusSuccess) {
                this.setState({
                    imageUrl: response.data.location,
                    message: "Image uploaded successfully!",
                    messageColor: 'green',
                });
            } else {
                this.setState({
                    message: response.data.message || "File upload failed.",
                    messageColor: 'red',
                });
            }
        } catch (err) {
            console.error("Error uploading file:", err);
            this.setState({ message: "File upload failed.", messageColor: 'red' });
        }
    };

    handleAddOrEditBanner = async () => {
        const { imageUrl, quote, storyLink, isEditing, editBannerId } = this.state;

        try {
            const token = getAccessToken();
            const bannerData = { imageUrl, quote, storyLink };

            let response;
            if (isEditing) {
                response = await Axios.put(`https://api.storytent.in/api/spotlight/edit`, {
                    id: editBannerId,
                    ...bannerData,
                }, {
                    headers: { token },
                });
            } else {
                response = await Axios.post("https://api.storytent.in/api/spotlight/create", bannerData, {
                    headers: { token },
                });
            }

            if (response.data.status === Constant.statusSuccess) {
                this.setState({
                    message: isEditing ? "Spotlight updated successfully" : "Spotlight added successfully",
                    messageColor: 'green'
                });
                this.fetchBanners();
                this.handleModalClose();
            } else {
                this.setState({ message: response.data.message, messageColor: 'red' });
            }
        } catch (err) {
            console.error(isEditing ? "Error updating spotlight:" : "Error adding spotlight:", err);
        }
    };

    handleEditBanner = async (id) => {
        try {
            const token = getAccessToken();
            const response = await Axios.get(`https://api.storytent.in/api/spotlight/getById/${id}`, {
                headers: { token },
            });
            const { imageUrl, quote, storyLink } = response.data.data;

            this.setState({
                showModal: true,
                isEditing: true,
                editBannerId: id,
                imageUrl,
                quote,
                storyLink,
            });
        } catch (err) {
            console.error("Error fetching spotlight details:", err);
        }
    };

    handleDeleteBanner = async (id) => {
        if (!window.confirm("Delete this Spotlight?")) return;
        try {
            const token = getAccessToken();
            const response = await Axios.delete(`https://api.storytent.in/api/spotlight/delete/${id}`, {
                headers: { token },
            });
            if (response.data.status === Constant.statusSuccess) {
                this.setState({ message: "Spotlight deleted successfully", messageColor: 'green' });
                this.fetchBanners();
            } else {
                this.setState({ message: response.data.message, messageColor: 'red' });
            }
        } catch (err) {
            console.error("Error deleting spotlight:", err);
        }
    };

    render() {
        const { banners, showModal, imageUrl, quote, storyLink, message, messageColor, isEditing } = this.state;

        return (
            <div className="content-page">
                <div className="content">
                    <h4 className="page-title-heading">Spotlight Management</h4>
                    <Button variant="info" className="mb-3" onClick={this.handleShowModal}>Add New Spotlight</Button>
                    <p style={{ color: messageColor }}>{message}</p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Quote</th>
                                <th>Story Link</th>
                                <th>Created At</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {banners.map((banner, index) => (
                                <tr key={index}>
                                    <td><img src={banner.imageUrl} alt="Banner" style={{ width: 50, height: 50 }} /></td>
                                    <td>{banner.quote}</td>
                                    <td><a href={banner.storyLink} target="_blank" rel="noopener noreferrer">View Story</a></td>
                                    <td>{new Date(banner.createdAt).toLocaleDateString()}</td>
                                    <td>
                                        <Button variant="warning" onClick={() => this.handleEditBanner(banner._id)}>Edit</Button>
                                        <Button variant="danger" onClick={() => this.handleDeleteBanner(banner._id)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Modal show={showModal} onHide={this.handleModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{isEditing ? "Edit Spotlight" : "Add New Spotlight"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="formFile">
                                    <Form.Label>Upload Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="file"
                                        onChange={this.handleFileChange}
                                    />
                                    <Button variant="primary" onClick={this.handleUploadFile}>Upload to S3</Button>
                                </Form.Group>
                                <Form.Group controlId="formImageUrl">
                                    <Form.Label>Image URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="imageUrl"
                                        value={imageUrl}
                                        onChange={this.handleChange}
                                        placeholder="Enter or use uploaded image URL"
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group controlId="formQuote">
                                    <Form.Label>Quote</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="quote"
                                        value={quote}
                                        onChange={this.handleChange}
                                        placeholder="Enter quote"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formStoryLink">
                                    <Form.Label>Story Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="storyLink"
                                        value={storyLink}
                                        onChange={this.handleChange}
                                        placeholder="Enter story link"
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleModalClose}>Close</Button>
                            <Button variant="primary" onClick={this.handleAddOrEditBanner}>
                                {isEditing ? "Update Spotlight" : "Add Spotlight"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default SpotlightManagement;
