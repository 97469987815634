import React, { Component } from 'react';
import Axios from 'axios';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';

class EditSubContest extends Component {
    state = {
        name: '',
        about: '',
        image: null,
        startDate: '',
        closureDate: '',
        startTime: '',
        closureTime: '',
        resultDate: '',
        resultTime: '',
        videoLink: '',
        totalNumberOfWinners: '',
        showSuccessPopup: false,
        showParticipantSection: false,
        participantName: '',
        participantAdded: false,
        participants: []
    };

    async componentDidMount() {
        await this.fetchContestData();
    }

    fetchContestData = async () => {
        try {
            const token = getAccessToken();
            const { match } = this.props;
            const { sId } = match.params;

            const response = await Axios.get(
                `https://api.storytent.in/api/openMic/getDataById/${sId}`,
                { headers: { 'token': token } }
            );
            const { data } = response;
            if (data.status === 'Success') {
                const contestData = data.data[0];
                this.setState({
                    name: contestData.name,
                    image: contestData.image, 
                    about: contestData.about,
                    startDate: contestData.startDate,
                    closureDate: contestData.closureDate,
                    startTime: contestData.startTime,
                    closureTime: contestData.closureTime,
                    resultDate: contestData.resultDate,
                    resultTime: contestData.resultTime,
                    videoLink: contestData.videoLink,
                    totalNumberOfWinners: contestData.totalNumberOfWinners
                });
            } else {
                console.error('Failed to fetch contest data:', data.message);
            }
        } catch (error) {
            console.error('Error fetching contest data:', error);
        }
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    handleImageChange = (e) => {
        this.setState({
            image: e.target.files[0]
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = getAccessToken();
            const { match } = this.props;
            const { sId } = match.params;

            const form_data = new FormData();
            form_data.append('name', this.state.name);
            form_data.append('about', this.state.about);
            form_data.append('image', this.state.image);
            form_data.append('startDate', this.state.startDate);
            form_data.append('closureDate', this.state.closureDate);
            form_data.append('startTime', this.state.startTime);
            form_data.append('closureTime', this.state.closureTime);
            form_data.append('resultDate', this.state.resultDate);
            form_data.append('resultTime', this.state.resultTime);
            form_data.append('videoLink', this.state.videoLink);
            form_data.append('totalNumberOfWinners', this.state.totalNumberOfWinners);

            const response = await Axios.patch(Constant.apiBasePath + `openMic/edit/${sId}`, form_data, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.status === 'Success') {
                this.setState({ showSuccessPopup: true });
            } else {
                // Handle failure
            }
        } catch (error) {
            console.error('Error editing sub-contest:', error);
        }
    };

    handleAddParticipant = async () => {
        try {
            const token = getAccessToken();
            const { subContestId } = this.props;
            const response = await Axios.post(Constant.apiBasePath + `openMic/add-participant/${subContestId}`, {
                name: this.state.participantName
            }, {
                headers: {
                    'token': token
                }
            });
            this.setState({ participantAdded: true });
        } catch (error) {
            console.error('Error adding participant:', error);
        }
    };

    fetchParticipants = async () => {
        try {
            const token = getAccessToken();
            const { subContestId } = this.props;
            const response = await Axios.get(Constant.apiBasePath + `openMic/getparticipantsById/${subContestId}`, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.status === 'Success') {
                this.setState({ participants: data.data });
            } else {
                // Handle failure
            }
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    };

    toggleParticipantSection = () => {
        this.setState(prevState => ({
            showParticipantSection: !prevState.showParticipantSection
        }), () => {
            if (this.state.showParticipantSection) {
                this.fetchParticipants();
            }
        });
    };

    closeSuccessPopup = () => {
        this.setState({ showSuccessPopup: false });
    };

    render() {
        const { name, about, startDate, closureDate, startTime, closureTime, resultDate, resultTime,image, videoLink, totalNumberOfWinners, showSuccessPopup, showParticipantSection, participantName, participantAdded, participants } = this.state;

        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title-heading">Edit Sub Contest</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="name">Name *</label>
                                            <input type="text" id="name" className="form-control" placeholder="Enter name" value={name} onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="about">About *</label>
                                            <textarea id="about" className="form-control" placeholder="Enter about" rows="4" value={about} onChange={this.handleChange}></textarea>
                                        </div>
                                        <div className="form-group mb-3 row">
                                            <div className="col-md-6">
                                                <label htmlFor="startDate">Start Date *</label>
                                                <input type="date" id="startDate" className="form-control" placeholder="Enter start date" value={startDate} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="closureDate">Closure Date *</label>
                                                <input type="date" id="closureDate" className="form-control" placeholder="Enter closure date" value={closureDate} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 row">
                                            <div className="col-md-6">
                                                <label htmlFor="startTime">Start Time *</label>
                                                <input type="time" id="startTime" className="form-control" placeholder="Enter start time" value={startTime} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="closureTime">Closure Time *</label>
                                                <input type="time" id="closureTime" className="form-control" placeholder="Enter closure time" value={closureTime} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 row">
                                            <div className="col-md-6">
                                                <label htmlFor="resultDate">Result Date *</label>
                                                <input type="date" id="resultDate" className="form-control" placeholder="Enter result date" value={resultDate} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="resultTime">Result Time*</label>
                                                <input type="time" id="resultTime" className="form-control" placeholder="Enter result time" value={resultTime} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 row">
                                            <div className="col-md-3">
                                                <label htmlFor="totalNumberOfWinners">Total Number of Winners *</label>
                                                <input type="number" id="totalNumberOfWinners" className="form-control" placeholder="Enter total number of winners" value={totalNumberOfWinners} onChange={this.handleChange} />
                                            </div>
                                            <div className="col-md-9">
                                                <label htmlFor="videoLink">Video Link *</label>
                                                <textarea id="videoLink" className="form-control" placeholder="Enter video link" rows="1" value={videoLink} onChange={this.handleChange}></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="image">Image *</label>
                                            <input type="file" id="image" className="form-control-file" onChange={this.handleImageChange} />
                                            <br />
                                            {image && <img src={image} alt="Sub Contest" style={{ maxWidth: '100%', maxHeight: '200px' }} />}
                                        </div>
                                        <button type="submit" className="btn btn-info">Update Sub Contest</button>
                                    </form>
                                    {showSuccessPopup && (
                                        <div className="popup">
                                            <button className="close-button" onClick={this.closeSuccessPopup}>X</button>
                                            <div className="success-message">
                                                <h3 style={{ color: 'skyblue' }}>Successfully updated</h3>
                                            </div>
                                        </div>
                                    )}

                                    {showParticipantSection && (
                                        <div className="popup">
                                            <button className="close-button" onClick={this.toggleParticipantSection}>Close</button>
                                            <div className="form-group mb-3">
                                                <label htmlFor="participantName">Enter Name *</label>
                                                <input type="text" id="participantName" className="form-control" placeholder="Enter participant name" value={participantName} onChange={(e) => this.setState({ participantName: e.target.value })} />
                                            </div>
                                            <div className="participant-list">
                                                <h4>Participants:</h4>
                                                <ul>
                                                    {participants.map((participant, index) => (
                                                        <li key={index}>{participant.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {/* Conditional rendering based on participant added state */}
                                            {participantAdded ? (
                                                <button className="btn btn-success" disabled>Added</button>
                                            ) : (
                                                <button onClick={this.handleAddParticipant} className="btn btn-info">Add</button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style>
                    {`
                      .popup {
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: white;
                        padding: 20px;
                        border-radius: 5px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                        width: 20%;
                        max-width: 800px; /* Set a maximum width */
                    }
                    
                    .close-button {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        background: none;
                        border: none;
                        cursor: pointer;
                        color: red;
                        font-size: 18px;
                    }
                    
                    .success-message {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 20px;
                    }
                    
                    .success-message h3 {
                        color: skyblue;
                        margin: 0;
                    }
                    
                    
                    .add-participant-button {
                        background-color: #17a2b8;
                        color: #fff;
                        border: none;
                        padding: 8px 16px;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    
                  
                    
                   
                    
                    
                    `}
                </style>
            </div>
        );
    }
}

export default EditSubContest;
