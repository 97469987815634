import React, { Component } from 'react';
import Axios from 'axios';
import { getAccessToken } from '../../hooks/AccessToken';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Constant from '../Constant';

class CourseEnrollments extends Component {
    state = {
        enrollments: [],
        showModal: false,
        newUserId: '',
        editEnrollmentId: null,
        editUserId: ''
    };

    componentDidMount() {
        this.fetchEnrollments();
    }

    fetchEnrollments = async () => {
        const { match } = this.props;
        const { courseId } = match.params; // Assuming 'courseId' is the param name

        try {
            const token = getAccessToken();
            const response = await Axios.get(`${Constant.apiBasePath}courseEnrollment/get-all-by-courseId/${courseId}`, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            console.log("Fetched Enrollments: ", data.data);
            this.setState({ enrollments: data.data || [] });
        } catch (error) {
            console.error('Error fetching enrollments:', error);
        }
    };

    addEnrollment = async () => {
        const { match } = this.props;
        const { courseId } = match.params; // Assuming 'courseId' is the param name

        const { newUserId } = this.state;

        try {
            const token = getAccessToken();
            const response = await Axios.post(`${Constant.apiBasePath}courseEnrollment`, {
                courseId: courseId,
                userId: newUserId
            }, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Course enrollment added successfully') {
                console.log('Enrollment added successfully');
                this.fetchEnrollments(); // Refresh enrollment list
                this.setState({
                    newUserId: '',
                    showModal: false
                });
            } else {
                console.error('Failed to add enrollment:', data.message);
            }
        } catch (error) {
            console.error('Error adding enrollment:', error);
        }
    };

    editEnrollment = async () => {
        const { editUserId, editEnrollmentId } = this.state;
        const { match } = this.props;
        const { courseId } = match.params; // Assuming 'courseId' is the param name

        try {
            const token = getAccessToken();
            const response = await Axios.patch(`${Constant.apiBasePath}courseEnrollment/${editEnrollmentId}`, {
                courseId: courseId,
                userId: editUserId
            }, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Course enrollment updated successfully') {
                console.log('Enrollment updated successfully');
                this.fetchEnrollments(); // Refresh enrollment list
                this.setState({
                    editEnrollmentId: null,
                    editUserId: '',
                    showModal: false
                });
            } else {
                console.error('Failed to update enrollment:', data.message);
            }
        } catch (error) {
            console.error('Error updating enrollment:', error);
        }
    };

    deleteEnrollment = async (id) => {
        try {
            const token = getAccessToken();
            const response = await Axios.delete(`${Constant.apiBasePath}courseEnrollment/${id}`, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Course enrollment deleted successfully') {
                console.log('Enrollment deleted successfully');
                this.fetchEnrollments(); // Refresh enrollment list
            } else {
                console.error('Failed to delete enrollment:', data.message);
            }
        } catch (error) {
            console.error('Error deleting enrollment:', error);
        }
    };

    handleOpenModal = (enrollment) => {
        if (enrollment) {
            this.setState({
                editEnrollmentId: enrollment._id,
                editUserId: enrollment.userId,
                showModal: true
            });
        } else {
            this.setState({ showModal: true });
        }
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            newUserId: '',
            editEnrollmentId: null,
            editUserId: ''
        });
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this enrollment?')) {
            this.deleteEnrollment(id);
        }
    };

    render() {
        const { enrollments, showModal, newUserId, editUserId, editEnrollmentId } = this.state;

        return (
            <div className="content-page">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="page-title-heading">Course Enrollments</h4>
                                    <button className="btn btn-info" onClick={() => this.handleOpenModal(null)}>Add Enrollment</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>user ID</th>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {enrollments.map((enrollment, index) => (
                                                <tr key={enrollment._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{enrollment.studentId._id}</td>
                                                    <td>{enrollment.studentId.name}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-primary"
                                                            style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }}
                                                            onClick={() => this.handleOpenModal(enrollment)}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                        <button
                                                            className="btn btn-danger"
                                                            style={{ marginLeft: '5px' }}
                                                            onClick={() => this.handleDelete(enrollment._id)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Add/Edit Enrollment"
                    style={{
                        content: {
                            width: '500px',
                            height: 'auto',
                            margin: 'auto',
                        }
                    }}
                >
                    <h2>{editEnrollmentId ? 'Edit Enrollment' : 'Add Enrollment'}</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="userId">User ID:</label>
                            <input
                                type="text"
                                id="userId"
                                name={editEnrollmentId ? 'editUserId' : 'newUserId'}
                                value={editEnrollmentId ? editUserId : newUserId}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="Enter User ID"
                                required
                            />
                        </div>
                        <button
                            type="button"
                            onClick={editEnrollmentId ? this.editEnrollment : this.addEnrollment}
                            className="btn btn-primary"
                        >
                            {editEnrollmentId ? 'Update' : 'Add'}
                        </button>
                        <button
                            type="button"
                            onClick={this.handleCloseModal}
                            className="btn btn-secondary"
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </button>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default CourseEnrollments;
