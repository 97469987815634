import React, { Component } from 'react';
import Axios from 'axios';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';

class AddSubContest extends Component {
    state = {
        name: '',
        about: '',
        image: null,
        startDate: '',
        closureDate: '',
        startTime: '',
        closureTime: '',
        resultDate: '',
        resultTime: '',
        videoLink: '',
        totalNumberOfWinners: '',
        showSuccessPopup: false,
        showParticipantSection: false,
        participantName: '',
        participantAdded: false,
        participants: []
    };


    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    handleImageChange = (e) => {
        this.setState({
            image: e.target.files[0]
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { match } = this.props; 
            const { cId } = match.params; 
           
            const token = getAccessToken();
            const form_data = new FormData();
            form_data.append('name', this.state.name);
            form_data.append('about', this.state.about);
            form_data.append('image', this.state.image);
            form_data.append('startDate', this.state.startDate);
            form_data.append('closureDate', this.state.closureDate);
            form_data.append('startTime', this.state.startTime);
            form_data.append('closureTime', this.state.closureTime);
            form_data.append('resultDate', this.state.resultDate);
            form_data.append('resultTime', this.state.resultTime);
            form_data.append('videoLink', this.state.videoLink);
            form_data.append('totalNumberOfWinners', this.state.totalNumberOfWinners);
    
            const response = await Axios.post(Constant.apiBasePath + `openMic/create/${cId}`, form_data, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.status === 'Success') {
                // Capture the ID of the created subcontest
                const subcontestId = data.data._id;
                // Use the captured ID to fetch or add participants
                this.setState({ 
                    showSuccessPopup: true,
                    subcontestId: subcontestId // Store the ID for later use
                });
            } else {
                // Handle failure
            }
        } catch (error) {
            console.error('Error adding sub-contest:', error);
        }
    };
    
    handleAddParticipant = async () => {
        try {
            const { subcontestId, participantName } = this.state;
            const token = getAccessToken();
            const response = await Axios.post(Constant.apiBasePath + `openMic/add-participant/${subcontestId}`, {
                name: participantName
            }, {
                headers: {
                    'token': token
                }
            });
            // Handle response
            this.setState({ participantAdded: true });
        } catch (error) {
            console.error('Error adding participant:', error);
        }
    };
    
    fetchParticipants = async () => {
        try {
            const { subcontestId } = this.state;
            const token = getAccessToken();
            const response = await Axios.get(Constant.apiBasePath + `openMic/getparticipantsById/${subcontestId}`, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.status === 'Success') {
                this.setState({ participants: data.data });
            } else {
                // Handle failure
            }
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    };
    

    toggleParticipantSection = () => {
        this.setState(prevState => ({
            showParticipantSection: !prevState.showParticipantSection
        }), () => {
            if (this.state.showParticipantSection) {
                this.fetchParticipants();
            }
        });
    };

    closeSuccessPopup = () => {
        this.setState({ showSuccessPopup: false });
    };

    render() {
        const { showSuccessPopup, showParticipantSection, participantName,participantAdded,participants   } = this.state;

        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title-heading">Add Sub Contest</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="name">Name *</label>
                                            <input type="text" id="name" className="form-control" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="about">About *</label>
                                            <textarea id="about" className="form-control" rows="4" onChange={this.handleChange}></textarea>
                                        </div>
                                        <div className="form-group mb-3 row">
    <div className="col-md-6">
        <label htmlFor="startDate">Start Date *</label>
        <input type="date" id="startDate" className="form-control" onChange={this.handleChange} />
    </div>
    <div className="col-md-6">
        <label htmlFor="closureDate">Closure Date *</label>
        <input type="date" id="closureDate" className="form-control" onChange={this.handleChange} />
    </div>
</div>
<div className="form-group mb-3 row">
    <div className="col-md-6">
        <label htmlFor="startTime">Start Time *</label>
        <input type="time" id="startTime" className="form-control" onChange={this.handleChange} />
    </div>
    <div className="col-md-6">
        <label htmlFor="closureTime">Closure Time *</label>
        <input type="time" id="closureTime" className="form-control" onChange={this.handleChange} />
    </div>
</div>
<div className="form-group mb-3 row">


                                        <div className="col-md-6">
                                            <label htmlFor="resultDate">Result Date *</label>
                                            <input type="date" id="resultDate" className="form-control" onChange={this.handleChange} />
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <label htmlFor="resultTime">Result Time*</label>
                                            <input type="time" id="resultTime" className="form-control" onChange={this.handleChange} />
                                        </div>
                                        </div>
                                        <div className="form-group mb-3 row">

                                        <div className="col-md-3">
                                            <label htmlFor="totalNumberOfWinners">Total Number of Winners *</label>
                                            <input type="number" id="totalNumberOfWinners" className="form-control" onChange={this.handleChange} />
                                        </div>

                                        <div className="col-md-9">
                                            <label htmlFor="videoLink">Video Link *</label>
                                            <textarea id="videoLink" className="form-control" rows="1" onChange={this.handleChange}></textarea>
                                        </div>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label htmlFor="image">Image *</label>
                                            <input type="file" id="image" className="form-control-file" accept="image/png, image/jpeg" onChange={this.handleImageChange} />
                                        </div>


                                        <button type="submit" className="btn btn-info">Add Sub Contest</button>
                                    </form>
                                    {showSuccessPopup && (
    <div className="popup">
        <button className="close-button" onClick={this.closeSuccessPopup}>X</button>
        <div className="success-message">
            <h3 style={{ color: 'skyblue' }}>Successfully sent</h3>
            <button onClick={this.toggleParticipantSection} className="add-participant-button">Add Participants</button>
        </div>
    </div>
)}

                                    {showParticipantSection && (
                                        <div className="popup">
                                            <button className="close-button" onClick={this.toggleParticipantSection}>Close</button>
                                            <div className="form-group mb-3">
                                                <label htmlFor="participantName">Enter Name *</label>
                                                <input type="text" id="participantName" className="form-control" value={participantName} onChange={(e) => this.setState({ participantName: e.target.value })} />
                                            </div>
                                            <div className="participant-list">
                                                <h4>Participants:</h4>
                                                <ul>
                                                    {participants.map((participant, index) => (
                                                        <li key={index}>{participant.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {/* Conditional rendering based on participant added state */}
                                            {participantAdded ? (
                                                <button className="btn btn-success" disabled>Added</button>
                                            ) : (
                                                <button onClick={this.handleAddParticipant} className="btn btn-info">Add</button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style>
                    {`
                      .popup {
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: white;
                        padding: 20px;
                        border-radius: 5px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                        width: 80%; /* Adjust width as needed */
                        max-width: 800px; /* Set a maximum width */
                    }
                    
                    .close-button {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        background: none;
                        border: none;
                        cursor: pointer;
                        color: red;
                        font-size: 18px;
                    }
                    
                    .success-message {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 20px;
                    }
                    
                    .success-message h3 {
                        color: skyblue;
                        margin: 0;
                    }
                    
                    
                    .add-participant-button {
                        background-color: #17a2b8;
                        color: #fff;
                        border: none;
                        padding: 8px 16px;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    
                    .add-participant-button:hover {
                        background-color: #117a8b;
                    }
                    
                    .participant-list {
                        margin-top: 20px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                    }
                    
                    .participant-list h4 {
                        margin-bottom: 10px;
                        width: 100%;
                    }
                    
                    .participant-list ul {
                        list-style: none;
                        padding: 0;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                    }
                    
                    .participant-list ul li {
                        margin-bottom: 5px;
                        flex-basis: calc(33.33% - 10px); /* Adjust width to fit 3 items in a row with some margin */
                        margin-right: 10px; /* Margin between participant items */
                    }
                    
                    `}
                </style>
            </div>
        );
    }
}

export default AddSubContest;
