import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

class DataManagement extends Component {
    state = {
        DataList: [],
        pageNo: 1,
        length: 0,
        newTitle: '',
        newCategory: '',
        newDescription: '',
        newCreatedBy: '',
        editingItem: null, // State for item being edited
        showPopup: false
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            let token = getAccessToken();
            const response = await Axios.get(
                Constant.apiBasePath + 'course/get-all',
                {
                    headers: {
                        'token': token
                    }
                }
            );
            let { data } = response;
            console.log('Fetched Data:', data.data);
            this.setState({
                DataList: data || [],  // Fallback to empty array if data is undefined
                pageNo: data.pageNo || 1,   // Default to page 1 if pageNo is undefined
                length: data.length || 0    // Default to 0 if length is undefined
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    togglePopup = (item = null) => {
        if (item) {
            this.setState({
                newTitle: item.title,
                newCategory: item.category,
                newDescription: item.description,
                newCreatedBy: item.createdBy,
                editingItem: item
            });
        } else {
            this.setState({
                newTitle: '',
                newCategory: '',
                newDescription: '',
                newCreatedBy: '',
                editingItem: null
            });
        }
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    addOrUpdateItem = async () => {
        try {
            const { newTitle, newCategory, newDescription, editingItem , newCreatedBy} = this.state;
            
            const title = newTitle.trim();
            const category = newCategory.trim();
            const description = String(newDescription).trim();
            const createdBy = String(newCreatedBy).trim();
    
            if (title === '' || category === '' || description === '' || createdBy === '') {
                alert('All fields are required');
                return;
            }
    
            let token = getAccessToken();
            let response;
            if (editingItem) {
                // Update existing item
                response = await Axios.patch(
                    Constant.apiBasePath + `course/${editingItem._id}`,
                    { title, category, description , createdBy},
                    { headers: { 'token': token } }
                );
            } else {
                // Add new item
                response = await Axios.post(
                    Constant.apiBasePath + 'course',
                    { title, category, description, createdBy },
                    { headers: { 'token': token } }
                );
            }
            let { data } = response;
            if (data.status === 'Success') {
                this.setState(prevState => ({
                    DataList: editingItem 
                        ? prevState.DataList.map(item => item._id === data.data._id ? data.data : item)
                        : [...prevState.DataList, data.data],
                    newTitle: '',
                    newCategory: '',
                    newDescription: '',
                    newCreatedBy: '',
                    editingItem: null,
                    showPopup: false
                }));
            } else {
                console.error('Failed to add/update item:', data.message);
            }
        } catch (error) {
            console.error('Error adding/updating item:', error);
        }
    };

    deleteItem = async (itemId) => {
        try {
            let token = getAccessToken();
            const response = await Axios.delete(
                `${Constant.apiBasePath}course/${itemId}`,
                { headers: { 'token': token } }
            );
            const { data } = response;
            if (data.status === 'Success') {
                this.setState(prevState => ({
                    DataList: prevState.DataList.filter(item => item._id !== itemId)
                }));
                alert('Item deleted successfully');
            } else {
                console.error('Failed to delete item:', data.message);
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    render() {
        const { DataList, length, pageNo, showPopup, newTitle, newCategory, newDescription ,newCreatedBy} = this.state;
        let i = (pageNo - 1) * Constant.perPage;
        let bodyData = '';

        console.log('DataList:', DataList);  // Debugging step

        if (DataList.length > 0) {
            bodyData = DataList.map(el => {
                i++;
                return (
                    <tr key={el._id}>
                        <td>{i}</td>
                        <td>{el.title}</td>
                        <td>{el.category}</td>
                        <td>{el.description}</td>
                        <td>{el.createdBy}</td>
                        <td>
                            <button className="btn btn-danger" onClick={() => { this.deleteItem(el._id) }}>
                                <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px' }} />
                            </button>
                            <button className="btn btn-primary" onClick={() => { this.togglePopup(el) }} style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }}>
                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                            </button>
                        </td>
                        <td>
                            <Link to={`/module/course/${el._id}`} className="btn btn-info">Modules</Link>
                        </td>
                        <td>
                            <Link to={`/course/enrollment/${el._id}`} className="btn btn-info">Enrollments</Link>
                        </td>
                    </tr>
                );
            });
        }

        let previous = 0;
        let next = 0;
        let customLength = 0

        if (pageNo !== 0) {
            previous = pageNo - 1;
            next = pageNo + 1;
        }

        if (length !== 0) {
            customLength = length;
        }

        return (
            <div className="content-page">
                <div className="row">
                    <div className="col-6">
                        <div className="page-title-box">
                            <h4 className="page-title-heading">Course Management</h4>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="float-right">
                            <button
                                type="button"
                                className="btn btn-info mb-2 mr-2"
                                onClick={() => this.togglePopup()}
                            >
                                Add New
                            </button>
                        </div>
                    </div>
                </div>
                {showPopup && (
                    <div style={popupContainerStyle}>
                        <div style={popupStyle}>
                            <div style={popupInnerStyle}>
                                <h4 style={{ textAlign: 'left' }}>{this.state.editingItem ? 'Edit Course' : 'Add Course'}</h4>
                                <input
                                    type="text"
                                    name="newTitle"
                                    placeholder="Enter Title"
                                    value={newTitle}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <input
                                    type="text"
                                    name="newCategory"
                                    placeholder="Enter Category"
                                    value={newCategory}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <input
                                    type="number"
                                    name="newDescription"
                                    placeholder="Enter Description"
                                    value={newDescription}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <input
                                    type="text"
                                    name="newCreatedBy"
                                    placeholder="Whose course is it?"
                                    value={newCreatedBy}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <div className="text-right mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={this.togglePopup}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary ml-2"
                                        onClick={this.addOrUpdateItem}
                                    >
                                        {this.state.editingItem ? 'Update' : 'Add'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Title</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>createdBy</th>
                                <th>Actions</th>
                                <th>Modules</th>
                                <th>Enrollments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bodyData}
                        </tbody>
                    </table>
                </div>
                <div className="pagination-container">
                    <button
                        className="btn btn-secondary"
                        disabled={pageNo === 1}
                        onClick={() => this.setState({ pageNo: previous }, this.fetchData)}
                    >
                        Previous
                    </button>
                    <span className="page-info">Page {pageNo} of {Math.ceil(customLength / Constant.perPage)}</span>
                    <button
                        className="btn btn-secondary"
                        disabled={i >= customLength}
                        onClick={() => this.setState({ pageNo: next }, this.fetchData)}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(DataManagement);

// CSS styles for popup
const popupContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const popupStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
};

const popupInnerStyle = {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
};

const inputStyle = {
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
};
