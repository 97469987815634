// Import necessary libraries and components
import React, { Component } from 'react';
import Axios from 'axios';
import { getAccessToken } from '../../hooks/AccessToken';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Constant from '../Constant';

class Participants extends Component {
    state = {
        participants: [],
        showModal: false,
        newParticipantName: '',
        editParticipantId: null,
        editParticipantName: '',
        editParticipantLocation: '',
        editParticipantPhone: '',
        editParticipantCity: '',
        editParticipantType: '',
        editParticipantLanguage: '',
        editParticipantAge: '',
        editParticipantPayId: '',
    };

    componentDidMount() {
        this.fetchParticipants();
    }

    fetchParticipants = async () => {
        const { match } = this.props;
        const { Id } = match.params;

        try {
            const token = getAccessToken();
            const response = await Axios.get(Constant.apiBasePath + `offlineOpenMic/participants/get-all/${Id}`, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            console.log("data:- ", data.data);
            
            this.setState({ participants: data.data || [] });
            
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    };

    addParticipant = async () => {
        const { match } = this.props;
        const { Id } = match.params; // Assuming Id is the openMicID
    
        try {
            const token = getAccessToken();
            const response = await Axios.post(Constant.apiBasePath + `offlineOpenMic/participants/create`, {
                location: this.state.editParticipantLocation, // Use the relevant state values
                openMicID: Id, // This should match the openMicID required by your API
                name: this.state.editParticipantName,
                phone: this.state.editParticipantPhone,
                city: this.state.editParticipantCity,
                participantType: this.state.editParticipantType,
                language: this.state.editParticipantLanguage,
                above_18_or_below: this.state.editParticipantAge === 'true', // Convert string to boolean
                payId: this.state.editParticipantPayId
            }, {
                headers: {
                    'token': token
                }
            });
    
            const { data } = response;
            if (data.message === 'Participant added successfully') {
                console.log('Participant added successfully');
                this.fetchParticipants(); // Refresh participant list
                this.setState({ 
                    newParticipantName: '', 
                    showModal: false 
                });
            } else {
                console.error('Failed to add participant:', data.message);
            }
        } catch (error) {
            console.error('Error adding participant:', error);
        }
    };

    editParticipant = async () => {
        const { editParticipantId, editParticipantName, editParticipantLocation, editParticipantPhone, editParticipantCity, editParticipantType, editParticipantLanguage, editParticipantAge, editParticipantPayId } = this.state;
        try {
            const token = getAccessToken();
            const response = await Axios.patch(Constant.apiBasePath + `offlineOpenMic/participants/edit/${editParticipantId}`, {
                name: editParticipantName,
                location: editParticipantLocation,
                phone: editParticipantPhone,
                city: editParticipantCity,
                participantType: editParticipantType,
                language: editParticipantLanguage,
                above_18_or_below: editParticipantAge,
                payId: editParticipantPayId
            }, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.message === 'Participant updated successfully')  {
                console.log('Participant updated successfully');
                this.fetchParticipants(); // Refresh participant list
                this.setState({ editParticipantId: null, showModal: false }); // Clear input and close modal
            } else {
                console.error('Failed to update participant:', data.message);
            }
        } catch (error) {
            console.error('Error updating participant:', error);
        }
    };

    deleteParticipant = async (id) => {
        try {
            const token = getAccessToken();
            const response = await Axios.delete(Constant.apiBasePath + `offlineOpenMic/participants/delete/${id}`, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            if (data.message === 'Participant deleted successfully') {
                console.log('Participant deleted successfully');
                this.fetchParticipants(); // Refresh participant list
            } else {
                console.error('Failed to delete participant:', data.message);
            }
        } catch (error) {
            console.error('Error deleting participant:', error);
        }
    };

    handleOpenModal = (participant) => {
        if (participant) {
            this.setState({
                editParticipantId: participant._id,
                editParticipantName: participant.name,
                editParticipantLocation: participant.location,
                editParticipantPhone: participant.phone,
                editParticipantCity: participant.city,
                editParticipantType: participant.participantType,
                editParticipantLanguage: participant.language,
                editParticipantAge: participant.above_18_or_below,
                editParticipantPayId: participant.payId,
                showModal: true
            });
        } else {
            this.setState({ showModal: true });
        }
    };

    handleCloseModal = () => {
        this.setState({ 
            showModal: false,
            editParticipantId: null,
            editParticipantName: '',
            editParticipantLocation: '',
            editParticipantPhone: '',
            editParticipantCity: '',
            editParticipantType: '',
            editParticipantLanguage: '',
            editParticipantAge: '',
            editParticipantPayId: '',
        });
    };

    handleInputChange = (e) => {
        this.setState({ newParticipantName: e.target.value });
    };

    handleEditInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this participant?')) {
            this.deleteParticipant(id);
        }
    };

    render() {
        const { participants, showModal, editParticipantName, editParticipantLocation, editParticipantPhone, editParticipantCity, editParticipantType, editParticipantLanguage, editParticipantAge, editParticipantPayId } = this.state;

        return (
            <div className="content-page">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="page-title-heading">Participants</h4>
                                    <button className="btn btn-info" onClick={() => this.handleOpenModal(null)}>Add New</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Location</th>
                                                <th>Phone</th>
                                                <th>City</th>
                                                <th>Type</th>
                                                <th>Language</th>
                                                <th>Age</th>
                                                <th>Pay ID</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {participants.map((participant, index) => (
                                                <tr key={participant._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{participant.name}</td>
                                                    <td>{participant.location}</td>
                                                    <td>{participant.phone}</td>
                                                    <td>{participant.city}</td>
                                                    <td>{participant.participantType}</td>
                                                    <td>{participant.language}</td>
                                                    <td>{participant.above_18_or_below ? 'Above 18' : 'Below 18'}</td>
                                                    <td>{participant.payId}</td>
                                                    <td>
                                                        <button className="btn btn-primary" style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }} onClick={() => this.handleOpenModal(participant)}>
                                                            <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                        <button className="btn btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.handleDelete(participant._id)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Add/Edit Participant"
                    style={{
                        content: {
                            width: '500px', // Adjust width as needed
                            height: 'auto', // Adjust height as needed
                            margin: 'auto',
                        }
                    }}
                >
                    <h2>{this.state.editParticipantId ? 'Edit Participant' : 'Add Participant'}</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="editParticipantName">Name:</label>
                            <input
                                type="text"
                                id="editParticipantName"
                                name="editParticipantName"
                                value={editParticipantName}
                                onChange={this.handleEditInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="editParticipantLocation">Location:</label>
                            <input
                                type="text"
                                id="editParticipantLocation"
                                name="editParticipantLocation"
                                value={editParticipantLocation}
                                onChange={this.handleEditInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="editParticipantPhone">Phone:</label>
                            <input
                                type="text"
                                id="editParticipantPhone"
                                name="editParticipantPhone"
                                value={editParticipantPhone}
                                onChange={this.handleEditInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="editParticipantCity">City:</label>
                            <input
                                type="text"
                                id="editParticipantCity"
                                name="editParticipantCity"
                                value={editParticipantCity}
                                onChange={this.handleEditInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="editParticipantType">Type:</label>
                            <input
                                type="text"
                                id="editParticipantType"
                                name="editParticipantType"
                                value={editParticipantType}
                                onChange={this.handleEditInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="editParticipantLanguage">Language:</label>
                            <input
                                type="text"
                                id="editParticipantLanguage"
                                name="editParticipantLanguage"
                                value={editParticipantLanguage}
                                onChange={this.handleEditInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="editParticipantAge">Age:</label>
                            <input
                                type="text"
                                id="editParticipantAge"
                                name="editParticipantAge"
                                value={editParticipantAge}
                                onChange={this.handleEditInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="editParticipantPayId">Pay ID:</label>
                            <input
                                type="text"
                                id="editParticipantPayId"
                                name="editParticipantPayId"
                                value={editParticipantPayId}
                                onChange={this.handleEditInputChange}
                                className="form-control"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={this.state.editParticipantId ? this.editParticipant : this.addParticipant}
                            className="btn btn-primary"
                        >
                            {this.state.editParticipantId ? 'Update' : 'Add'}
                        </button>
                        <button
                            type="button"
                            onClick={this.handleCloseModal}
                            className="btn btn-secondary"
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </button>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Participants;
