import React, { Component } from 'react';
import Axios from 'axios';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';

class EditContestLinks extends Component {
    state = {
        OpenMicContestName: '',
        livestreamingLink: '',
        zoomLink: '',
        codeZoomLink: '',
        active: true,
        submitted: false,
        showSuccessPopup: false
    };

    async componentDidMount() {
        await this.fetchContestLinks();
    }

    fetchContestLinks = async () => {
        try {
            const token = getAccessToken();
            const response = await Axios.get(
                'https://api.storytent.in/api/openMic/getContestLinks',
                { headers: { 'token': token } }
            );
            const { data } = response;
            if (data.status === 'Success') {
                const [firstLink] = data.data;
                if (firstLink) {
                    const { OpenMicContestName, livestreamingLink, zoomLink, codeZoomLink, active } = firstLink;
                    this.setState({
                        contestLinks: data.data,
                        OpenMicContestName,
                        livestreamingLink,
                        zoomLink,
                        codeZoomLink,
                        active
                    });
                }
            } else {
                console.error('Failed to fetch contest links:', data.message);
            }
        } catch (error) {
            console.error('Error fetching contest links:', error);
        }
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.type === 'select-one' ? JSON.parse(e.target.value) : e.target.value
        });
    };
    
   
    handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted');
        try {
            const token = getAccessToken();
            const { OpenMicContestName, livestreamingLink, zoomLink, codeZoomLink, active } = this.state;
            const response = await Axios.patch(
                'https://api.storytent.in/api/openMic/editLinks/6605618c162a3358b67d2077',
                { OpenMicContestName, livestreamingLink, zoomLink, codeZoomLink, active },
                { headers: { 'token': token } }
            );
            const { data } = response;
            if (data.status === 'Success') {
                console.log('Contest links edited successfully:', data);
                this.setState({ submitted: true, showSuccessPopup: true });
            } else {
                console.error('Failed to edit contest links:', data.message);
            }
        } catch (error) {
            console.error('Error editing contest links:', error);
        }
    };

    closeSuccessPopup = () => {
        this.setState({ showSuccessPopup: false });
    };

    render() {
        const { OpenMicContestName, livestreamingLink, zoomLink, codeZoomLink, submitted, showSuccessPopup, active } = this.state;

        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title-heading">Edit Contest Links</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label htmlFor="OpenMicContestName">Open Mic Contest Name *</label>
                                            <input type="text" id="OpenMicContestName" className="form-control" value={OpenMicContestName} onChange={this.handleChange} required />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="livestreamingLink">Livestreaming Link *</label>
                                            <input type="text" id="livestreamingLink" className="form-control" value={livestreamingLink} onChange={this.handleChange} required />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="zoomLink">Zoom Link *</label>
                                            <input type="text" id="zoomLink" className="form-control" value={zoomLink} onChange={this.handleChange} required />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="codeZoomLink">Code Zoom Link *</label>
                                            <input type="text" id="codeZoomLink" className="form-control" value={codeZoomLink} onChange={this.handleChange} required />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="active">Active *</label>
                                            <select id="active" className="form-control" value={active} onChange={this.handleChange}>
                                                <option value={true}>True</option>
                                                <option value={false}>False</option>
                                            </select>
                                        </div>
                                        <button type="submit" className="btn btn-info" disabled={submitted}>{submitted ? 'Edited' : 'Edit Contest Links'}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showSuccessPopup && (
                    <div className="popup">
                        <button className="close-button" onClick={this.closeSuccessPopup}>X</button>
                        <div className="success-message">
                            <h3 style={{ color: 'green' }}>Contest Links Edited Successfully</h3>
                        </div>
                    </div>
                )}

                <style>
                    {`
                    .popup {
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: white;
                        padding: 20px;
                        border-radius: 5px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    }
                    
                    .close-button {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        background: none;
                        border: none;
                        cursor: pointer;
                        color: red;
                        font-size: 18px;
                    }
                    
                    .success-message {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    
                    .success-message h3 {
                        color: green;
                        margin: 0;
                    }
                    `}
                </style>
            </div>
        );
    }
}

export default EditContestLinks;
