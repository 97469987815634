import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import "../../css/style.css";

import Constant from "../Constant";
import $ from "jquery";
import { getAccessToken } from "../../hooks/AccessToken";

class AddUpdate extends Component {
  /*..................................... default state object ............................................*/

  state = {
    title: "",
    criteria: "", // New field for criteria
    type: "", // Ensure this will be 'writer' or 'reader'
    icon: null, // Changed from image to icon
    status: 1, // Optional field for status, defaulting to 1
    GetData: {},
    ERROR: true,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleImage = (e) => {
    this.setState({
      [e.target.id]: e.target.files[0],
    });
  };

  /*.................. Integrate API for add, update data...............................................*/

  handleSubmit = async (event) => {
    try {
      let token = getAccessToken();
      event.preventDefault();
      let id = this.props.match.params.id;
      let form_data = new FormData();

      if (id === undefined) {
        if (this.state.image === null) {
          $("#error-image-msg").text("File is required");
          $("#error-image-msg").css("color", "red");
          return false;
        }
        console.log(this.state.icon);

        let fileName = this.state.icon.name;
        let extension = fileName.split(".").pop();

        if (extension !== "jpg" && extension !== "png") {
          $("#error-image-msg").text("Only jpg and png file type are allow !");
          $("#error-image-msg").css("color", "red");
          return false;
        }

        form_data.append("title", this.state.title);
        form_data.append("criteria", this.state.criteria); // New field for criteria
        form_data.append("badgeIcon", this.state.icon, this.state.icon.name); // Using 'icon' instead of 'badgeIcon'
        form_data.append("type", this.state.type); // Ensure this is either 'writer' or 'reader'

        if (this.state.status !== undefined) {
          form_data.append("status", this.state.status);
        }
        console.log(form_data);

        Axios.post(Constant.apiBasePath + "badge/create", form_data, {
          headers: { token: token },
        })
          .then((response) => {
            let data = response.data;
            if (data.status === Constant.statusSuccess) {
              $("#gen-message").text(data.message);
              $("#gen-message").css("color", "green");
              setTimeout(function () {
                window.location.href = "/badge-management";
              }, 3000);
            } else {
              $("#gen-message").text(data.message);
              $("#gen-message").css("color", "red");
            }
          })
          .catch((error) => {
            if (error.response === undefined) {
              $("#gen-message").text("API is not working");
              $("#gen-message").css("color", "red");
              return false;
            } else {
              $("#gen-message").text(error.response.data.message);
              $("#gen-message").css("color", "red");
              return false;
            }
          });
      } else {
        let { GetData } = this.state;
        const form_data = new FormData();

        // Append title
        this.state.title
          ? form_data.append("title", this.state.title)
          : form_data.append("title", GetData.title);

        // Append criteria (new field)
        this.state.criteria
          ? form_data.append("criteria", this.state.criteria)
          : form_data.append("criteria", GetData.criteria);

        // Append icon (changed from image to icon)
        this.state.icon
          ? form_data.append("icon", this.state.icon, this.state.icon.name)
          : form_data.append("icon", GetData.icon);

        // Append type
        this.state.type
          ? form_data.append("type", this.state.type)
          : form_data.append("type", GetData.type);

        // Append status if available
        if (this.state.status !== undefined) {
          form_data.append("status", this.state.status);
        } else {
          form_data.append("status", GetData.status);
        }

        console.log(form_data);
        Axios.patch(Constant.apiBasePath + "badge/edit/" + id, form_data, {
          headers: { token: token },
        })
          .then((response) => {
            let data = response.data;
            if (data.status === Constant.statusSuccess) {
              $("#gen-message").text(data.message);
              $("#gen-message").css("color", "green");
              setTimeout(function () {
                window.location.href = "/badge-management";
              }, 3000);
            }
          })
          .catch((error) => {
            if (error.response === undefined) {
              $("#gen-message").text("API is not working");
              $("#gen-message").css("color", "red");
              return false;
            } else {
              $("#gen-message").text(error.response.data.message);
              $("#gen-message").css("color", "red");
              return false;
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // get donation data by id

  async getGetData(id) {
    let token = getAccessToken();

    let req = {
      method: "GET",
      url: Constant.apiBasePath + "badge/getDataById/" + id,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: token,
      },
      json: true,
    };

    Axios(req)
      .then((response) => {
        const { data } = response;
        if (data.status === Constant.statusSuccess) {
          this.setState({ GetData: data.data[0], ERROR: false });
        } else {
          $("#gen-message").text(data.message);
          $("#gen-message").css("color", "red");
        }
      })
      .catch((error) => "Something Error");
  }

  /*---------------------------Reload function------------------------------------------ */

  componentDidMount() {
    // get data by id
    if (this.props.match.params.id) {
      this.getGetData(this.props.match.params.id);
    }
  }

  /*.................................end API .................................................................*/

  render() {
    const { GetData } = this.state;
    let title = "";
    let criteria = ""; // New field for criteria
    let icon = "";
    let type = "";

    let btnVal = "Add";
    let heading = "Add Badge";
    let types = (
      <>
        <option id="0" value="">
          Select one
        </option>
        <option id="writer" value="writer">
          Writer
        </option>
        <option id="reader" value="reader">
          Reader
        </option>
      </>
    );
    if (GetData.title !== undefined) {
      title = GetData.title;
      criteria = GetData.criteria; // New field for criteria
      icon = GetData.icon; // Changed from image to icon
      type = GetData.type;

      btnVal = "Update";
      heading = "Update Badge";
      if (type === "writer") {
        types = (
          <>
            <option id="0" value="">
              Select one
            </option>
            <option id="writer" value="writer" selected>
              Writer
            </option>
            <option id="reader" value="reader">
              Reader
            </option>
          </>
        );
      } else {
        types = (
          <>
            <option id="0" value="">
              Select one
            </option>
            <option id="writer" value="writer">
              Writer
            </option>
            <option id="reader" value="reader" selected>
              Reader
            </option>
          </>
        );
      }
    }

    return (
      <div className="content-page">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/badge-management">Badge Management</Link>
                    </li>
                    <li className="breadcrumb-item active">Add New Badge</li>
                  </ol>
                </div>
                <h4 className="page-title-heading">{heading}</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={this.handleSubmit}>
                    <p id="gen-message"></p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label htmlFor="type" className="state">
                            Writer/Reader *
                          </label>
                          <select
                            id="type"
                            className="form-control"
                            value={this.state.type}
                            onChange={this.handleChange}
                          >
                            {types}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label htmlFor="title" className="state">
                            Title *
                          </label>
                          <input
                            type="text"
                            id="title"
                            className="form-control"
                            value={this.state.title}
            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label htmlFor="criteria" className="state">
                            Criteria *
                          </label>
                          <input
                            type="text"
                            id="criteria"
                            className="form-control"
                            value={this.state.criteria}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      {icon ? (
                        <>
                          <div className="col-md-5">
                            <div className="form-group mb-3">
                              <label htmlFor="icon" className="state">
                                Upload Icon *
                              </label>
                              <input
                                type="file"
                                id="icon"
                                className="form-control"
                                onChange={this.handleImage}
                              />
                              <p id="error-icon-msg" />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="form-group mb-3">
                              <label htmlFor="state" className="state">
                                Icon
                              </label>
                              <img
                                src={icon}
                                className="form-control"
                                alt="StoryTent"
                                style={{ width: 50, height: 50 }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="icon" className="state">
                              Upload Icon *
                            </label>
                            <input
                              type="file"
                              id="icon"
                              className="form-control"
                              onChange={this.handleImage}
                            />
                            <p id="error-icon-msg" />
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label htmlFor="status" className="state">
                            Status
                          </label>
                          <input
                            type="number"
                            id="status"
                            className="form-control"
                            value={this.state.status}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-info">
                      {btnVal}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AddUpdate;
