import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'; 
import Axios from 'axios';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AddSubContest from './AddUpdate';
import { Link } from 'react-router-dom'; 


class DataManagement extends Component {
    state = {
        DataList: [],
        pageNo: 1,
        length: 0,
        newName: '',
        showPopup: false,
        showAddSubContestPopup: false
    };

    componentDidMount() {
        this.fetchData();
    }

    toggleAddSubContestPopup = () => {
        this.setState(prevState => ({
            showAddSubContestPopup: !prevState.showAddSubContestPopup
        }));

        this.props.history.push('/add-sub-contest');
    };

    fetchData = async () => {
        try {
            let token = getAccessToken();
            const response = await Axios.post(
                Constant.apiBasePath + 'openMic/get-all',
                {},
                { headers: { 'token': token } }
            );
            let { data } = response;
            if (data.status === 'Success') {
                this.setState({
                    DataList: data.data,
                    pageNo: data.pageNo,
                    length: data.length
                });
            } else {
                console.error('Failed to fetch data:', data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    togglePopup = () => {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    };

    handleInputChange = (e) => {
        this.setState({
            newName: e.target.value,
        });
    };

    addNewItem = async () => {
        try {
            const { newName } = this.state;
            let token = getAccessToken();
            const response = await Axios.post(
                Constant.apiBasePath + 'openMic/create',
                { name: newName },
                { headers: { 'token': token } }
            );
            let { data } = response;
            if (data.status === 'Success') {
                this.setState(prevState => ({
                    DataList: [...prevState.DataList, data.data], // Append new item to existing list
                    newName: '', // Clear input field after adding new item
                    showPopup: false // Close the popup after successful addition
                }));
            } else {
                console.error('Failed to add new item:', data.message);
            }
        } catch (error) {
            console.error('Error adding new item:', error);
        }
    };

    deleteItem = async (itemId) => {
        try {
            let token = getAccessToken();
            const response = await Axios.delete(
                `${Constant.apiBasePath}openMic/${itemId}`,
                { headers: { 'token': token } }
            );
            const { data } = response;
            if (data.status === 'Success') {
                // Remove the deleted item from the DataList
                this.setState(prevState => ({
                    DataList: prevState.DataList.filter(item => item._id !== itemId)
                }));
                // Show success message
                alert('Item deleted successfully');
            } else {
                console.error('Failed to delete item:', data.message);
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    render() {
        const { DataList, length, pageNo, showPopup, showAddSubContestPopup, newName } = this.state;
        let i = (pageNo - 1) * Constant.perPage;
        let bodyData = '';
    
        if (DataList.length > 0) {
            bodyData = DataList.map(el => {
                i++;
                let status = "Inactive";
                let btnClass = "btn-danger";
                if (el.status === 1) {
                    status = "Active";
                    btnClass = "btn-success";
                }
            
                return (
                    <tr key={el._id}>
                        <td>{i}</td>
                        <td>{el.name}</td>
                        <td>
                            <button className={`btn ${btnClass}`} onClick={() => { this.changeStatus(el._id, el.status) }}>{status}</button>
                        </td>
                        <td>
                            <button className="btn btn-danger" onClick={() => { this.deleteItem(el._id) }}>
                                <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px' }} />
                            </button>
                            <button className="btn btn-primary" onClick={() => { /* Add your edit logic here */ }} style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }}>
                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                            </button>
                        </td>
                        <td>
                        <td>
    <Link to={`/sub-openmics/${el._id}`} className="btn btn-info">Sub open mics</Link> 
</td>
                                               </td>
                    </tr>
                );
            });
        }
    
        let previous = 0;
        let next = 0;
        let customLength = 0
    
        if (pageNo !== 0) {
            previous = pageNo - 1;
            next = pageNo + 1;
        }
    
        if (length !== 0) {
            customLength = length;
        }
    
        return (
            <div className="content-page">
            <div className="row">
                <div className="col-6">
                    <div className="page-title-box">
                        <h4 className="page-title-heading">Open Mic Management</h4>
                    </div>
                </div>
                <div className="col-6">
                    <div className="float-right">
                    <Link to="/add-contestlinks" className="btn btn-info mb-2 mr-2">Add Contest Links</Link> 

                        <button
                            type="button"
                            className="btn btn-info mb-2 mr-2"
                            onClick={this.togglePopup}
                        >
                            Add New
                        </button>
                    </div>
                </div>
            </div>
                {showPopup && (
                    <div style={popupContainerStyle}>
                        <div style={popupStyle}>
                            <div style={popupInnerStyle}>
                                <h4 style={{ textAlign: 'left' }}>Add Contest</h4>
                                <input
                                    type="text"
                                    placeholder="Enter Name"
                                    value={newName}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <div style={buttonGroupStyle}>
                                    <button className="btn btn-info" style={buttonStyle} onClick={this.addNewItem}>
                                        Add
                                    </button>
                                    <div style={{ width: '10px' }} />
                                    <button className="btn btn-danger" style={buttonStyle} onClick={this.togglePopup}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
    
                {showAddSubContestPopup && <AddSubContest />}
    
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <select id="selectValue" className="form-control">
                                            <option value="all">Select one</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <p id="gen-message"></p>
                                    </div>
                                </div>
    
                                <div className="tab-content">
                                    <div className="tab-pane show active" id="buttons-table-preview">
                                        <table id="complex-header-datatable" className="table dt-responsive nowrap">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bodyData}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
}
export default withRouter(DataManagement);

const popupContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
};

const popupStyle = {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '5px',
};

const popupInnerStyle = {
    textAlign: 'center',
};

const inputStyle = {
    marginBottom: '10px', 
};

const buttonGroupStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px', 
};

const buttonStyle = {
    marginRight: '5px', 
};
