import React, { useEffect } from 'react';

const GoogleAuth = () => {
  useEffect(() => {
    const loadGapiScript = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.async = true;
      script.onload = () => {
        handleClientLoad();
      };
      document.body.appendChild(script);
    };

    const handleClientLoad = () => {
      window.gapi.load('client:auth2', initClient);
    };

    const initClient = () => {
      window.gapi.client
        .init({
          apiKey: 'AIzaSyAT3kHaMSRCjaZ2_AEKBfVHM0MTZUbF4yU', 
          clientId: '709948892619-bb9su32m8uc9jb51jk6mfuikk5qlip2n.apps.googleusercontent.com', // OAuth Client ID
          discoveryDocs: [
            'https://analyticsreporting.googleapis.com/$discovery/rest?version=v4',
          ],
          scope: 'https://www.googleapis.com/auth/analytics.readonly',
        })
        .then(() => {
          window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
          updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
        });
    };

    const updateSigninStatus = (isSignedIn) => {
      if (isSignedIn) {
        makeApiCall();
      } else {
        handleAuthClick();
      }
    };

    const handleAuthClick = () => {
      window.gapi.auth2.getAuthInstance().signIn();
    };

    const makeApiCall = () => {
      window.gapi.client.analyticsreporting.reports
        .batchGet({
          reportRequests: [
            {
              viewId: 'YOUR_VIEW_ID',
              dateRanges: [{ startDate: '30daysAgo', endDate: 'today' }],
              metrics: [{ expression: 'ga:sessions' }, { expression: 'ga:pageviews' }],
              dimensions: [{ name: 'ga:pageTitle' }],
            },
          ],
        })
        .then((response) => {
          console.log('Analytics Data:', response);
        });
    };

    // Load the gapi script when component mounts
    loadGapiScript();
  }, []);

  return <div>Loading Google Analytics Data...</div>;
};

export default GoogleAuth;
