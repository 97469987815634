import React, { Component } from 'react';
import Axios from 'axios';
import { getAccessToken } from '../../hooks/AccessToken';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Constant from '../Constant';
import { Link } from 'react-router-dom';

class Modules extends Component {
    state = {
        modules: [],
        showModal: false,
        newTitle: '',
        editModuleId: null,
        editTitle: ''
    };

    componentDidMount() {
        this.fetchModules();
    }

    fetchModules = async () => {
        const { match } = this.props;
        const { Id } = match.params;

        try {
            const token = getAccessToken();
            const response = await Axios.get(Constant.apiBasePath + `module/course/${Id}`, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            console.log("data: ", data.data);
            this.setState({ modules: data.data || [] });
        } catch (error) {
            console.error('Error fetching modules:', error);
        }
    };

    addModule = async () => {
        const { match } = this.props;
        const { Id } = match.params;

        try {
            const token = getAccessToken();
            const response = await Axios.post(Constant.apiBasePath + `module`, {
                title: this.state.newTitle,
                courseId : Id
            }, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Module added successfully') {
                console.log('Module added successfully');
                this.fetchModules(); // Refresh module list
                this.setState({
                    newTitle: '',
                    showModal: false
                });
            } else {
                console.error('Failed to add Module:', data.message);
            }
        } catch (error) {
            console.error('Error adding Module:', error);
        }
    };

    editModule = async () => {
        const { editTitle, editModuleId } = this.state;
        const { match } = this.props;
        const { Id } = match.params;
        try {
            const token = getAccessToken();
            const response = await Axios.put(Constant.apiBasePath + `module/${editModuleId}`, {
                title: editTitle,
                courseId : Id
            }, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Module updated successfully') {
                console.log('Module updated successfully');
                this.fetchModules(); // Refresh module list
                this.setState({
                    editModuleId: null,
                    editTitle: '',
                    showModal: false
                });
            } else {
                console.error('Failed to update module:', data.message);
            }
        } catch (error) {
            console.error('Error updating module:', error);
        }
    };

    deleteModule = async (id) => {
        try {
            const token = getAccessToken();
            const response = await Axios.delete(Constant.apiBasePath + `module/${id}`, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Module deleted successfully') {
                console.log('Module deleted successfully');
                this.fetchModules(); // Refresh module list
            } else {
                console.error('Failed to delete module:', data.message);
            }
        } catch (error) {
            console.error('Error deleting module:', error);
        }
    };

    handleOpenModal = (module) => {
        if (module) {
            this.setState({
                editModuleId: module._id,
                editTitle: module.title,
                showModal: true
            });
        } else {
            this.setState({ showModal: true });
        }
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            newTitle: '',
            editModuleId: null,
            editTitle: ''
        });
    };

    handleInputChange = (e) => {
        this.setState({ newTitle: e.target.value });
    };

    handleEditInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this module?')) {
            this.deleteModule(id);
        }
    };

    render() {
        const { modules, showModal, newTitle, editTitle } = this.state;

        return (
            <div className="content-page">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="page-title-heading">Modules</h4>
                                    <button className="btn btn-info" onClick={() => this.handleOpenModal(null)}>Add New</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Actions</th>
                                                <th>Modules</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modules.map((module, index) => (
                                                <tr key={module._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{module.title}</td>
                                                    <td>
                                                        <button className="btn btn-primary" style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }} onClick={() => this.handleOpenModal(module)}>
                                                            <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                        <button className="btn btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.handleDelete(module._id)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                    </td>
                                                    <td>
                            <Link to={`/lesson/module/${module._id}`} className="btn btn-info">Lesson</Link>
                        </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Add/Edit Module"
                    style={{
                        content: {
                            width: '500px', // Adjust width as needed
                            height: 'auto', // Adjust height as needed
                            margin: 'auto',
                        }
                    }}
                >
                    <h2>{this.state.editModuleId ? 'Edit Module' : 'Add Module'}</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="moduleTitle">Title:</label>
                            <input
                                type="text"
                                id="moduleTitle"
                                name={this.state.editModuleId ? 'editTitle' : 'newTitle'}
                                value={this.state.editModuleId ? editTitle : newTitle}
                                onChange={this.state.editModuleId ? this.handleEditInputChange : this.handleInputChange}
                                className="form-control"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={this.state.editModuleId ? this.editModule : this.addModule}
                            className="btn btn-primary"
                        >
                            {this.state.editModuleId ? 'Update' : 'Add'}
                        </button>
                        <button
                            type="button"
                            onClick={this.handleCloseModal}
                            className="btn btn-secondary"
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </button>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Modules;
