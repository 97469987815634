import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AddSubContest from './AddUpdate';
import { Link } from 'react-router-dom';

class DataManagement extends Component {
    state = {
        DataList: [],
        participants: [],
        pageNo: 1,
        length: 0,
        newName: '',
        newLocation: '',
        newAmount: '',
        newOpenMicID: '',
        editingItem: null, // New state for the item being edited
        showPopup: false,
        showAddSubContestPopup: false
    };

    componentDidMount() {
        this.fetchData();
    }

    toggleAddSubContestPopup = () => {
        this.setState(prevState => ({
            showAddSubContestPopup: !prevState.showAddSubContestPopup
        }));
        this.props.history.push('/add-sub-contest');
    };

    fetchData = async () => {
        try {
            let token = getAccessToken();
            const response = await Axios.get(
                Constant.apiBasePath + 'offlineOpenMic/get-all',
                {
                    headers: {
                        'token': token
                    }
                }
            );
            let { data } = response;
            console.log('Fetched Data:', data.data);
            this.setState({
                DataList: data.data || [],  // Fallback to empty array if data is undefined
                pageNo: data.pageNo || 1,   // Default to page 1 if pageNo is undefined
                length: data.length || 0    // Default to 0 if length is undefined
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    togglePopup = (item = null) => {
        if (item) {
            this.setState({
                newName: item.name,
                newLocation: item.location,
                newAmount: item.amount,
                newOpenMicID: item.openMicID,
                editingItem: item
            });
        } else {
            this.setState({
                newName: '',
                newLocation: '',
                newAmount: '',
                newOpenMicID: '',
                editingItem: null
            });
        }
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    addOrUpdateItem = async () => {
        try {
            const { newName, newLocation, newAmount, newOpenMicID, editingItem } = this.state;
            
            // Convert non-string values to string for trimming
            const name = newName.trim();
            const location = newLocation.trim();
            const amount = String(newAmount).trim();
            const openMicID = newOpenMicID.trim();
    
            if (name === '' || location === '' || amount === '' || openMicID === '') {
                alert('All fields are required');
                return;
            }
    
            let token = getAccessToken();
            let response;
            if (editingItem) {
                // Update existing item
                response = await Axios.patch(
                    Constant.apiBasePath + `offlineOpenMic/edit/${editingItem._id}`,
                    { name, location, amount, openMicID },
                    { headers: { 'token': token } }
                );
            } else {
                // Add new item
                response = await Axios.post(
                    Constant.apiBasePath + 'offlineOpenMic/create',
                    { name, location, amount, openMicID },
                    { headers: { 'token': token } }
                );
            }
            let { data } = response;
            if (data.status === 'Success') {
                this.setState(prevState => ({
                    DataList: editingItem 
                        ? prevState.DataList.map(item => item._id === data.data._id ? data.data : item)
                        : [...prevState.DataList, data.data],
                    newName: '',
                    newLocation: '',
                    newAmount: '',
                    newOpenMicID: '',
                    editingItem: null,
                    showPopup: false
                }));
            } else {
                console.error('Failed to add/update item:', data.message);
            }
        } catch (error) {
            console.error('Error adding/updating item:', error);
        }
    };
    

    deleteItem = async (itemId) => {
        try {
            let token = getAccessToken();
            const response = await Axios.delete(
                `${Constant.apiBasePath}offlineOpenMic/${itemId}`,
                { headers: { 'token': token } }
            );
            const { data } = response;
            if (data.status === 'Success') {
                this.setState(prevState => ({
                    DataList: prevState.DataList.filter(item => item._id !== itemId)
                }));
                alert('Item deleted successfully');
            } else {
                console.error('Failed to delete item:', data.message);
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    render() {
        const { DataList, length, pageNo, showPopup, showAddSubContestPopup, newName, newLocation, newAmount, newOpenMicID } = this.state;
        let i = (pageNo - 1) * Constant.perPage;
        let bodyData = '';

        console.log('DataList:', DataList);  // Debugging step

        if (DataList.length > 0) {
            bodyData = DataList.map(el => {
                i++;
                let status = el.active === 1 ? "Active" : "Inactive";
                let btnClass = el.active === 1 ? "btn-success" : "btn-danger";

                return (
                    <tr key={el._id}>
                        <td>{i}</td>
                        <td>{el.name}</td>
                        <td>{el.location}</td>
                        <td>{el.amount}</td>
                        <td>{el.openMicID}</td>
                        <td>
                            <button className={`btn ${btnClass}`} onClick={() => { this.changeStatus(el._id, el.active) }}>{status}</button>
                        </td>
                        <td>
                            <button className="btn btn-danger" onClick={() => { this.deleteItem(el._id) }}>
                                <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px' }} />
                            </button>
                            <button className="btn btn-primary" onClick={() => { this.togglePopup(el) }} style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }}>
                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                            </button>
                        </td>
                        <td>
                            <Link to={`/participants/${el._id}`} className="btn btn-info">Participants</Link>
                        </td>
                    </tr>
                );
            });
        }

        let previous = 0;
        let next = 0;
        let customLength = 0

        if (pageNo !== 0) {
            previous = pageNo - 1;
            next = pageNo + 1;
        }

        if (length !== 0) {
            customLength = length;
        }

        return (
            <div className="content-page">
                <div className="row">
                    <div className="col-6">
                        <div className="page-title-box">
                            <h4 className="page-title-heading">Offline Open Mic Management</h4>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="float-right">
                            <button
                                type="button"
                                className="btn btn-info mb-2 mr-2"
                                onClick={() => this.togglePopup()}
                            >
                                Add New
                            </button>
                        </div>
                    </div>
                </div>
                {showPopup && (
                    <div style={popupContainerStyle}>
                        <div style={popupStyle}>
                            <div style={popupInnerStyle}>
                                <h4 style={{ textAlign: 'left' }}>{this.state.editingItem ? 'Edit Offline Open Mic' : 'Add Offline Open Mic'}</h4>
                                <input
                                    type="text"
                                    name="newName"
                                    placeholder="Enter Name"
                                    value={newName}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <input
                                    type="text"
                                    name="newLocation"
                                    placeholder="Enter Location"
                                    value={newLocation}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <input
                                    type="number"
                                    name="newAmount"
                                    placeholder="Enter Amount"
                                    value={newAmount}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <input
                                    type="text"
                                    name="newOpenMicID"
                                    placeholder="Enter Open Mic ID"
                                    value={newOpenMicID}
                                    onChange={this.handleInputChange}
                                    style={inputStyle}
                                />
                                <div className="text-right mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={this.togglePopup}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary ml-2"
                                        onClick={this.addOrUpdateItem}
                                    >
                                        {this.state.editingItem ? 'Update' : 'Add'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Amount</th>
                                <th>Open Mic ID</th>
                                <th>Status</th>
                                <th>Actions</th>
                                <th>Participants</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bodyData}
                        </tbody>
                    </table>
                </div>
                <div className="pagination-container">
                    <button
                        className="btn btn-secondary"
                        disabled={pageNo === 1}
                        onClick={() => this.setState({ pageNo: previous }, this.fetchData)}
                    >
                        Previous
                    </button>
                    <span className="page-info">Page {pageNo} of {Math.ceil(customLength / Constant.perPage)}</span>
                    <button
                        className="btn btn-secondary"
                        disabled={pageNo >= Math.ceil(customLength / Constant.perPage)}
                        onClick={() => this.setState({ pageNo: next }, this.fetchData)}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    }
}

const popupContainerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const popupStyle = {
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '20px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    width: '400px'
};

const popupInnerStyle = {
    margin: '0 auto',
    width: '100%'
};

const inputStyle = {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #ddd',
    borderRadius: '5px'
};

export default withRouter(DataManagement);
