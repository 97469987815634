import React, { Component } from 'react'
import Axios from 'axios';
import { Link } from 'react-router-dom';
import '../../css/style.css';

import $ from 'jquery';
import Constant from '../Constant';
import { getAccessToken } from '../../hooks/AccessToken';

class AddUpdate extends Component {

    state = {
        name: '',
        image: null,
        about: '',
        startDate: "",
        startTime: "",
        closureDate: "",
        closureTime: "",
        resultDate: "",
        resultTime: "",
        totalNumberOfWinners: '',
        storyPrice: '',
        poemPrice: '',
        runnerUpPrizeMoney: '',  
        winnerPrizeMoney: '',   
        winnerTag: '',           // Added state
        getData: {},
        selectArea: 0,
        ERROR: true
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    handleImageChange = (e) => {
        this.setState({
            [e.target.id]: e.target.files[0]
        })
    };

    handleSubmit = async event => {
        try {
            event.preventDefault();
            let token = getAccessToken();
            let id = this.props.match.params.id;
            const form_data = new FormData();

            if (id === undefined) {
                if (this.state.image === null) {
                    $("#error-banner-msg").text('File is required');
                    $("#error-banner-msg").css("color", "red");
                    return false;
                }

                let fileName = this.state.image.name;
                let extension = fileName.split('.').pop();

                if (extension !== 'jpg' && extension !== 'png') {
                    $("#error-banner-msg").text('Only jpg and png file type are allowed!');
                    $("#error-banner-msg").css("color", "red");
                    return false;
                }

                form_data.append('name', this.state.name);
                form_data.append('image', this.state.image, this.state.image.name);
                form_data.append('about', this.state.about);
                form_data.append('startDate', this.state.startDate);
                form_data.append('startTime', this.state.startTime);
                form_data.append('closureDate', this.state.closureDate);
                form_data.append('closureTime', this.state.closureTime);
                form_data.append('resultDate', this.state.resultDate);
                form_data.append('resultTime', this.state.resultTime);
                form_data.append('totalNumberOfWinners', this.state.totalNumberOfWinners);
                form_data.append('storyPrice', this.state.storyPrice);
                form_data.append('poemPrice', this.state.poemPrice);
                form_data.append('runnerUpPrizeMoney', this.state.runnerUpPrizeMoney);  // Added field
                form_data.append('winnerPrizeMoney', this.state.winnerPrizeMoney);      // Added field
                form_data.append('winnerTag', this.state.winnerTag);                    // Added field

                Axios.post(Constant.apiBasePath + 'contest/create', form_data, { headers: { 'token': token } })
                    .then(response => {
                        const getResults = response.data.message;

                        if (response.data.status === Constant.statusSuccess) {
                            $('#gen-message').text(getResults);
                            $('#gen-message').css('color', 'green');
                            setTimeout(function () { window.location.href = '/contest-management'; }, 3000);
                        } else {
                            $('#gen-message').text(getResults);
                            $('#gen-message').css('color', 'red');
                            return false;
                        }
                    });
            } else {
                let getData = this.state.getData;
                (this.state.name) ? form_data.append('name', this.state.name) : form_data.append('name', getData.name);
                (this.state.image) ? form_data.append('image', this.state.image, this.state.image.name) : form_data.append('image', getData.image);
                (this.state.about) ? form_data.append('about', this.state.about) : form_data.append('about', getData.about);
                (this.state.startDate) ? form_data.append('startDate', this.state.startDate) : form_data.append('startDate', getData.startDate);
                (this.state.startTime) ? form_data.append('startTime', this.state.startTime) : form_data.append('startTime', getData.startTime);
                (this.state.closureDate) ? form_data.append('closureDate', this.state.closureDate) : form_data.append('closureDate', getData.closureDate);
                (this.state.closureTime) ? form_data.append('closureTime', this.state.closureTime) : form_data.append('closureTime', getData.closureTime);
                (this.state.resultDate) ? form_data.append('resultDate', this.state.resultDate) : form_data.append('resultDate', getData.resultDate);
                (this.state.resultTime) ? form_data.append('resultTime', this.state.resultTime) : form_data.append('resultTime', getData.resultTime);
                (this.state.totalNumberOfWinners) ? form_data.append('totalNumberOfWinners', this.state.totalNumberOfWinners) : form_data.append('totalNumberOfWinners', getData.totalNumberOfWinners);
                (this.state.storyPrice) ? form_data.append('storyPrice', this.state.storyPrice) : form_data.append('storyPrice', getData.storyPrice);
                (this.state.poemPrice) ? form_data.append('poemPrice', this.state.poemPrice) : form_data.append('poemPrice', getData.poemPrice);
                (this.state.runnerUpPrizeMoney) ? form_data.append('runnerUpPrizeMoney', this.state.runnerUpPrizeMoney) : form_data.append('runnerUpPrizeMoney', getData.runnerUpPrizeMoney);  // Added field
                (this.state.winnerPrizeMoney) ? form_data.append('winnerPrizeMoney', this.state.winnerPrizeMoney) : form_data.append('winnerPrizeMoney', getData.winnerPrizeMoney);      // Added field
                (this.state.winnerTag) ? form_data.append('winnerTag', this.state.winnerTag) : form_data.append('winnerTag', getData.winnerTag);                    // Added field

                Axios.patch(Constant.apiBasePath + 'contest/edit/' + id, form_data, { headers: { 'token': token } })
                    .then(response => {
                        const getResults = response.data.message;

                        if (response.data.status === Constant.statusSuccess) {
                            $('#gen-message').text(getResults);
                            $('#gen-message').css('color', 'green');
                            setTimeout(function () { window.location.href = '/contest-management'; }, 3000);
                        } else {
                            $('#gen-message').text(getResults);
                            $('#gen-message').css('color', 'red');
                            return false;
                        }
                    });
            }

        } catch (err) {
            console.log(err);
        }
    }

    getgetData(id) {
        let token = getAccessToken();
        let req = {
            method: 'GET',
            url: Constant.apiBasePath + 'contest/getDataById/' + id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'token': token
            },
            json: true
        }
        Axios(req).then(response => {
            const { data } = response;
            if (data.status === Constant.statusSuccess) {
                this.setState({ getData: data.data[0], ERROR: false });
            } else {
                this.setState({ getData: '', ERROR: false });
            }
        }).catch(error => ('Something Error'));
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        if (id !== undefined) {
            this.getgetData(id);
        }
    }

    render() {
        const { getData } = this.state;
        let name = '';
        let image = '';
        let about = '';
        let startDate = '';
        let startTime = '';
        let closureDate = '';
        let closureTime = '';
        let resultDate = '';
        let resultTime = '';
        let totalNumberOfWinners = '';
        let storyPrice = '';
        let poemPrice = '';
        let runnerUpPrizeMoney = '';  // Added
        let winnerPrizeMoney = '';    // Added
        let winnerTag = '';           // Added

        let btnVal = 'Add';
        let heading = 'Add Contest';

        if (getData.name !== undefined) {
            name = getData.name;
            image = (getData.image === undefined) ? '' : getData.image;
            about = getData.about;
            startDate = getData.startDate;
            startTime = getData.startTime;
            closureDate = getData.closureDate;
            closureTime = getData.closureTime;
            resultDate = getData.resultDate;
            resultTime = getData.resultTime;
            totalNumberOfWinners = getData.totalNumberOfWinners;
            storyPrice = getData.storyPrice;
            poemPrice = getData.poemPrice;
            runnerUpPrizeMoney = getData.runnerUpPrizeMoney;  // Added
            winnerPrizeMoney = getData.winnerPrizeMoney;      // Added
            winnerTag = getData.winnerTag;                    // Added
            btnVal = 'Update';
            heading = 'Update Contest';
        }
        
        return (
            <div className="content-page">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/contest-management">Contest Management</Link></li>
                                        <li className="breadcrumb-item active">{heading}</li>
                                    </ol>
                                </div>
                                <h4 className="page-title-heading">{heading}</h4>
                            </div>
                        </div>
                    </div>   
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body"> 
                                    <form onSubmit={this.handleSubmit}>
                                        <p id="gen-message"></p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Name *</label>
                                                    <input type="text" id="name" className="form-control" defaultValue={name} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Image *</label>
                                                    <input type="file" id="image" className="form-control" onChange={this.handleImageChange} />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">About *</label>
                                                    <textarea id="about" className="form-control" defaultValue={about} onChange={this.handleChange} required></textarea>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Start Date *</label>
                                                    <input type="date" id="startDate" className="form-control" defaultValue={startDate} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Start Time *</label>
                                                    <input type="time" id="startTime" className="form-control" defaultValue={startTime} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Closure Date *</label>
                                                    <input type="date" id="closureDate" className="form-control" defaultValue={closureDate} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Closure Time *</label>
                                                    <input type="time" id="closureTime" className="form-control" defaultValue={closureTime} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Result Date *</label>
                                                    <input type="date" id="resultDate" className="form-control" defaultValue={resultDate} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Result Time *</label>
                                                    <input type="time" id="resultTime" className="form-control" defaultValue={resultTime} onChange={this.handleChange} required/> {/* Added input for resultTime */}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Total Number Of Winners *</label>
                                                    <input type="number" id="totalNumberOfWinners" className="form-control" defaultValue={totalNumberOfWinners} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Story Price *</label>
                                                    <input type="number" id="storyPrice" className="form-control" defaultValue={storyPrice} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Poem Price *</label>
                                                    <input type="number" id="poemPrice" className="form-control" defaultValue={poemPrice} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Runner-up price money</label>
                                                    <input type="number" id="runnerUpPrizeMoney" className="form-control" defaultValue={runnerUpPrizeMoney} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Winner Prize Money</label>
                                                    <input type="number" id="winnerPrizeMoney" className="form-control" defaultValue={winnerPrizeMoney} onChange={this.handleChange} required/>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="update" className="update">Winner tag</label>
                                                    <input type="text" id="winnerTag" className="form-control" defaultValue={winnerTag} onChange={this.handleChange} required/>
                                                </div> 
                                                <div className="form-group mb-0">
                                                    <button type="submit" className="btn btn-primary waves-effect waves-light">{btnVal}</button>
                                                </div>
                                            </div>
                                        </div> 
                                    </form>
                                </div>
                            </div> 
                        </div>
                    </div>     
                </div> 
            </div>
        );
    }
}

export default AddUpdate;
