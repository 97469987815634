import React, { Component } from 'react';
import Axios from 'axios';
import { getAccessToken } from '../../hooks/AccessToken';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Constant from '../Constant';
import { Link } from 'react-router-dom';

class Lessons extends Component {
    state = {
        lessons: [],
        showModal: false,
        newTitle: '',
        newVideoLink: '',
        editLessonId: null,
        editTitle: '',
        editVideoLink: ''
    };

    componentDidMount() {
        this.fetchLessons();
    }

    fetchLessons = async () => {
        const { match } = this.props;
        const { Id } = match.params; // Module ID

        try {
            const token = getAccessToken();
            const response = await Axios.get(Constant.apiBasePath + `lesson/module/${Id}`, {
                headers: {
                    'token': token
                }
            });
            const { data } = response;
            console.log("data: ", data.data);
            this.setState({ lessons: data.data || [] });
        } catch (error) {
            console.error('Error fetching lessons:', error);
        }
    };

    addLesson = async () => {
        const { match } = this.props;
        const { Id } = match.params; // Module ID

        try {
            const token = getAccessToken();
            const response = await Axios.post(Constant.apiBasePath + `lesson`, {
                title: this.state.newTitle,
                videoLink: this.state.newVideoLink,
                moduleId: Id
            }, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Lesson added successfully') {
                console.log('Lesson added successfully');
                this.fetchLessons(); // Refresh lesson list
                this.setState({
                    newTitle: '',
                    newVideoLink: '',
                    showModal: false
                });
            } else {
                console.error('Failed to add Lesson:', data.message);
            }
        } catch (error) {
            console.error('Error adding Lesson:', error);
        }
    };

    editLesson = async () => {
        const { editTitle, editVideoLink, editLessonId } = this.state;
        try {
            const token = getAccessToken();
            const response = await Axios.put(Constant.apiBasePath + `lesson/${editLessonId}`, {
                title: editTitle,
                videoLink: editVideoLink
            }, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Lesson updated successfully') {
                console.log('Lesson updated successfully');
                this.fetchLessons(); // Refresh lesson list
                this.setState({
                    editLessonId: null,
                    editTitle: '',
                    editVideoLink: '',
                    showModal: false
                });
            } else {
                console.error('Failed to update lesson:', data.message);
            }
        } catch (error) {
            console.error('Error updating lesson:', error);
        }
    };

    deleteLesson = async (id) => {
        try {
            const token = getAccessToken();
            const response = await Axios.delete(Constant.apiBasePath + `lesson/${id}`, {
                headers: {
                    'token': token
                }
            });

            const { data } = response;
            if (data.message === 'Lesson deleted successfully') {
                console.log('Lesson deleted successfully');
                this.fetchLessons(); // Refresh lesson list
            } else {
                console.error('Failed to delete lesson:', data.message);
            }
        } catch (error) {
            console.error('Error deleting lesson:', error);
        }
    };

    handleOpenModal = (lesson) => {
        if (lesson) {
            this.setState({
                editLessonId: lesson._id,
                editTitle: lesson.title,
                editVideoLink: lesson.videoLink || '',
                showModal: true
            });
        } else {
            this.setState({ showModal: true });
        }
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            newTitle: '',
            newVideoLink: '',
            editLessonId: null,
            editTitle: '',
            editVideoLink: ''
        });
    };

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this lesson?')) {
            this.deleteLesson(id);
        }
    };

    render() {
        const { lessons, showModal, newTitle, newVideoLink, editTitle, editVideoLink } = this.state;

        return (
            <div className="content-page">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="page-title-heading">Lessons</h4>
                                    <button className="btn btn-info" onClick={() => this.handleOpenModal(null)}>Add New</button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Video Link</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lessons.map((lesson, index) => (
                                                <tr key={lesson._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{lesson.title}</td>
                                                    <td>{lesson.videoLink || 'No video link'}</td>
                                                    <td>
                                                        <button className="btn btn-primary" style={{ backgroundColor: '#17a2b8', marginLeft: '5px' }} onClick={() => this.handleOpenModal(lesson)}>
                                                            <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                        <button className="btn btn-danger" style={{ marginLeft: '5px' }} onClick={() => this.handleDelete(lesson._id)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '12px', color: '#fff' }} />
                                                        </button>
                                                    </td>
                                                    <td>
                            <Link to={`/lesson/lesson/${module._id}`} className="btn btn-info">Lesson</Link>
                        </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Add/Edit Lesson"
                    style={{
                        content: {
                            width: '500px',
                            height: 'auto',
                            margin: 'auto',
                        }
                    }}
                >
                    <h2>{this.state.editLessonId ? 'Edit Lesson' : 'Add Lesson'}</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="lessonTitle">Title:</label>
                            <input
                                type="text"
                                id="lessonTitle"
                                name={this.state.editLessonId ? 'editTitle' : 'newTitle'}
                                value={this.state.editLessonId ? editTitle : newTitle}
                                onChange={this.handleInputChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="videoLink">Video Link (optional):</label>
                            <input
                                type="text"
                                id="videoLink"
                                name={this.state.editLessonId ? 'editVideoLink' : 'newVideoLink'}
                                value={this.state.editLessonId ? editVideoLink : newVideoLink}
                                onChange={this.handleInputChange}
                                className="form-control"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={this.state.editLessonId ? this.editLesson : this.addLesson}
                            className="btn btn-primary"
                        >
                            {this.state.editLessonId ? 'Update' : 'Add'}
                        </button>
                        <button
                            type="button"
                            onClick={this.handleCloseModal}
                            className="btn btn-secondary"
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </button>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Lessons;
